import { Authority } from '@/shared/security/authority';
import ExploreManagerPage from '@/pages/manager/explore/ExploreManagerPage.vue';
import TeamRequestsManagerPage from '@/pages/manager/team-requests/TeamRequestsManagerPage.vue';
import TeamRequestsDetailManagerPage from '@/pages/manager/team-requests-detail/TeamRequestsDetailManagerPage.vue';
import CheckoutManagerPage from '@/pages/manager/checkout/CheckoutManagerPage.vue';
import StemdoerManagerPage from '@/pages/manager/stemdoer/StemdoerDetailManagerPage.vue';

export default [
  {
    path: '/manager/explore',
    name: 'exploreManager',
    component: ExploreManagerPage,
    meta: { authorities: [Authority.GESTOR] }
  },
  {
    path: '/manager/team-requests',
    name: 'teamRequestsManager',
    component: TeamRequestsManagerPage,
    meta: { authorities: [Authority.GESTOR] }
  },
  {
    path: '/manager/checkout',
    name: 'checkoutManager',
    component: CheckoutManagerPage,
    meta: { authorities: [Authority.GESTOR] }
  },
  {
    path: '/manager/team-requests/:id',
    name: 'teamRequestByIdManager',
    component: TeamRequestsDetailManagerPage,
    meta: { authorities: [Authority.GESTOR] }
  },
  {
    path: '/manager/stemdoer/:id',
    name: 'stemdoerByIdManager',
    component: StemdoerManagerPage,
    props: (route: { params: { id: any } }) => ({ stemdoerId: route.params.id } as { stemdoerId: string }),
    meta: { authorities: [Authority.GESTOR] }
  }
];
