import { render, staticRenderFns } from "./NewProposalConfirmationModal.vue?vue&type=template&id=1604d4a2&scoped=true"
import script from "./newProposalConfirmationModal.ts?vue&type=script&lang=ts&external"
export * from "./newProposalConfirmationModal.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1604d4a2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1604d4a2')) {
      api.createRecord('1604d4a2', component.options)
    } else {
      api.reload('1604d4a2', component.options)
    }
    module.hot.accept("./NewProposalConfirmationModal.vue?vue&type=template&id=1604d4a2&scoped=true", function () {
      api.rerender('1604d4a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/sections/shared/checkout/buttons-group/modal/manager/new-proposal-confirmation-modal/NewProposalConfirmationModal.vue"
export default component.exports