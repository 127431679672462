import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ITeamRequest } from '@/shared/model/team-request.model';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';

interface GetAllTeamRequestsByClientIdBody {
  requestStatus: RequestStatus[];
}

const baseApiUrl = 'api/team-requests';

export default class TeamRequestService {
  public find(id: string, isHide?: boolean): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`, {
          params: {
            isHide: isHide
          }
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllTeamRequestsByClientId(clientId: string, body?: GetAllTeamRequestsByClientIdBody, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(baseApiUrl + `/client/${clientId}?${buildPaginationQueryOpts(paginationQuery)}`, body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllRequestsByManager(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/manager?${buildPaginationQueryOpts(paginationQuery)}`, {})
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public blockAndPartialUpdate(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/block`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public validateAndPartialUpdate(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/validate`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public approveAndPartialUpdate(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/approve`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public resetAndPartialUpdate(entity: ITeamRequest): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/reset`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public calculateTempTotal(clientId: string, stemdoerSeniorities: string[]): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/temp-total`, {
          clientId: clientId,
          stemdoerSeniorities: stemdoerSeniorities
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public changeStatus(teamRequestId: string, status: string): Promise<ITeamRequest> {
    return new Promise<ITeamRequest>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${teamRequestId}/status/${status}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
