import { Module } from 'vuex';
import { ManagerStateStorable, manager } from './managerPagesStore';

export interface PagesStateStorable {
  manager: ManagerStateStorable;
}

export const pagesStore: Module<PagesStateStorable, any> = {
  namespaced: true,
  modules: {
    manager
  }
};
