var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "bg-white", attrs: { id: "stemdoer" } },
    [
      _vm.isLoaded
        ? _c("StemdoerDetail", {
            attrs: { stemdoer: _vm.stemdoer, onAddToCart: () => {} },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }