var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isLoaded
    ? _c("StemdoerDetail", {
        attrs: {
          stemdoer: _vm.stemdoer,
          onAddToCart: () => _vm.addStemdoerToCart(),
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }