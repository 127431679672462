var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    staticClass: "custom-v-selector",
    attrs: {
      label: _vm.placeholder,
      items: _vm.items,
      "item-value": "id",
      "item-text": "desc",
      "return-object": "",
      dense: "",
      multiple: "",
      "persistent-hint": "",
      flat: "",
      solo: "",
      "menu-props": { offsetY: true },
    },
    on: {
      change: _vm.handleChange,
      focus: _vm.handleFocus,
      blur: _vm.handleBlur,
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ index }) {
          return [
            index === 0
              ? _c(
                  "span",
                  {
                    staticClass: "pt-1 pb-1 text-body-1-medium text--lighten1",
                  },
                  [_vm._v("\n      " + _vm._s(_vm.labelDisplay) + "\n    ")]
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "div",
              {
                class: [
                  "text--text",
                  "text--base",
                  "select-container",
                  { "selected-item": _vm.isItemSelected(item) },
                  { "text-body-1-bold": _vm.isItemSelected(item) },
                  { "text-body-1-bold": _vm.isAllSelected },
                  { "text-body-1": !_vm.isItemSelected(item) },
                ],
              },
              [
                _c("span", [_vm._v(_vm._s(item.desc))]),
                _vm._v(" "),
                _vm.isItemSelected(item) || _vm.isAllSelected
                  ? _c("font-awesome-icon", {
                      staticClass: "ml-2 mr-1",
                      staticStyle: { "font-size": "18px" },
                      attrs: { icon: ["fas", "check"] },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedItems,
      callback: function ($$v) {
        _vm.selectedItems = $$v
      },
      expression: "selectedItems",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }