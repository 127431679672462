export enum ExtendedTeamRequestStatus {
  MANAGER_AND_PENDING_AND_USER_MODIFIED = 'MANAGER_AND_PENDING_AND_USER_MODIFIED',
  MANAGER_AND_PENDING_AND_USER_NOT_MODIFIED = 'MANAGER_AND_PENDING_AND_USER_NOT_MODIFIED',
  MANAGER_AND_VALIDATED_AND_USER_MODIFIED = 'MANAGER_AND_VALIDATED_AND_USER_MODIFIED',
  MANAGER_AND_VALIDATED_AND_USER_NOT_MODIFIED = 'MANAGER_AND_VALIDATED_AND_USER_NOT_MODIFIED',
  MANAGER_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE = 'MANAGER_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE',
  CLIENT_AND_CANCELLED = 'CLIENT_AND_CANCELLED',
  CLIENT_AND_SAVED = 'CLIENT_AND_SAVED',
  CLIENT_AND_SAVED_AND_NOT_ALL_STEMDOERS_AVAILABLE = 'CLIENT_AND_SAVED_AND_NOT_ALL_STEMDOERS_AVAILABLE',
  CLIENT_AND_PENDING = 'CLIENT_AND_PENDING',
  CLIENT_AND_VALIDATED_AND_MODIFIED_BY_MANAGER = 'CLIENT_AND_VALIDATED_AND_MODIFIED_BY_MANAGER',
  CLIENT_AND_VALIDATED_AND_NOT_MODIFIED_BY_MANAGER = 'CLIENT_AND_VALIDATED_AND_NOT_MODIFIED_BY_MANAGER',
  CLIENT_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE = 'CLIENT_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE',
  CLIENT_AND_ON_GOING = 'CLIENT_AND_ON_GOING'
}
