var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "SHeaderSection",
    {
      attrs: {
        title: _vm.$t("checkout.title"),
        onBackButtonClick: _vm.goToExplore,
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "px-0 pt-10 pb-0 mx-0", style: { minWidth: "100%" } },
        [
          _c(
            "v-row",
            { staticClass: "mb-6", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                _vm._l(
                  _vm.teamRequest?.stemdoerRates,
                  function (stemdoerRates) {
                    return _c(
                      "v-row",
                      {
                        key: stemdoerRates?.stemdoer?.id,
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-0 mb-4" },
                          [
                            _c("SCardStemdoer", {
                              attrs: {
                                stemdoer: stemdoerRates.stemdoer,
                                rate: stemdoerRates.rate,
                                cardOptions: _vm.stemdoerCardOptions,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 ml-6 mb-4", attrs: { cols: "5" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("CheckoutOverview", {
                            attrs: {
                              hourlyCost: _vm.hourlyCost,
                              numStemdoers:
                                _vm.teamRequest.stemdoerRates.length,
                              client: _vm.clientSelected,
                              isUserClient: _vm.isUserClient,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-6", attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          [
                            _c("ButtonsGroup", {
                              attrs: {
                                teamRequest: _vm.teamRequest,
                                teamRequestStatus:
                                  _vm.extendedTeamRequestStatus,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.stemdoerList.length > 0
                    ? _c(
                        "v-row",
                        { staticClass: "mt-6", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { id: "checkout-chart" } },
                                [
                                  _c("SRadarStemdoersTechs", {
                                    attrs: { stemdoerList: _vm.stemdoerList },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ModalStemdoerDetail", {
        attrs: {
          isOpen: _vm.isStemdoerDetailModalOpen,
          onClose: _vm.closeStemdoerDetailModal,
          stemdoer: _vm.selectedStemdoer,
          rate: _vm.selectedStemdoerRate,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }