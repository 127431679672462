import { computed, defineComponent, PropType } from 'vue';
import { Radar } from 'vue-chartjs';
import { Stemdoer } from '@/shared/model/stemdoer.model';
import { useI18N } from '@/plugins/i18n';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export default defineComponent({
  props: {
    stemdoerList: {
      type: Array as PropType<Stemdoer[]>,
      required: true
    }
  },
  components: {
    Radar
  },
  setup(props) {
    const i18n = useI18N();
    const data = computed(() => {
      const { labels, dataset } = generateTechAndDataLabels(props.stemdoerList);
      return {
        menuStartDate: false,
        menuEndDate: false,
        loader: null,
        loading: false,
        clipboard: false,
        dialog: false,
        errorPopUp: false,
        isProposalTeam: false,
        labels: labels,
        datasets: [
          {
            label: i18n.t(`technologyMaster.labelRadar`).toString(),
            backgroundColor: 'rgba(179,181,198,0.2)',
            borderColor: 'rgba(179,181,198,1)',
            pointBackgroundColor: 'rgba(179,181,198,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
            data: dataset
          }
        ]
      };
    });
    const options = computed(() => {
      let max = 7;
      const sortedData = data.value.datasets[0].data.sort((a, b) => b - a);
      if (sortedData[0] < max) {
        max = sortedData[0] + 1 > 7 ? 7 : sortedData[0] + 1;
      }

      return {
        plugins: {
          legend: {
            display: false,
            title: {
              display: false
            }
          }
        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          r: {
            ticks: {
              stepSize: 1,
              display: false
            },
            pointLabels: {
              display: true
            },
            suggestedMin: 0,
            suggestedMax: max
          }
        },
        elements: {
          line: {
            borderWidth: 3
          }
        }
      };
    });

    const generateTechAndDataLabels = (stemdoerList: Stemdoer[]) => {
      const techLabels = new Set<string>();
      const techScoresMap: { [key: string]: number[] } = {};
      stemdoerList.forEach(stemdoer => {
        stemdoer.technologyScores.forEach(tech => {
          const techLabel = i18n.t(`technologyMaster.list.${tech.idTechnology}`).toString();
          techLabels.add(techLabel);
          if (!techScoresMap[techLabel]) {
            techScoresMap[techLabel] = [];
          }
          techScoresMap[techLabel].push(tech.score);
        });
      });
      let avgScores = Object.entries(techScoresMap).map(([tech, scores]) => {
        const sum = scores.reduce((a, b) => a + b, 0);
        const avg = sum / scores.length;
        return { tech, avg };
      });
      avgScores.sort((a, b) => b.avg - a.avg);
      avgScores = avgScores.slice(0, 5);

      const labels = avgScores.map(item => item.tech);
      const dataset = avgScores.map(item => item.avg);
      return { labels: labels, dataset: dataset };
    };

    return {
      data,
      options
    };
  }
});
