var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "teamStemdoers" } },
    [
      this.loaded
        ? _c("div", { staticClass: "checkout" }, [
            _c("div", { staticClass: "toolBoxTop" }, [
              _c("div", [_c("SButtonBack")], 1),
              _vm._v(" "),
              _c("div", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("label.teams.selected.myTeam")) + " "),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end align-items-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnPDF",
                      staticStyle: {
                        "background-color": "rgba(255, 255, 255, 0) !important",
                      },
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.loading,
                        fab: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.loading = true
                          _vm.startLoading()
                        },
                      },
                    },
                    [
                      _c("v-img", {
                        staticClass: "iconPDF",
                        attrs: { src: "/content/svgs/pdf-button.svg" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-1" }, [_vm._v("PDF")]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "checkout-container" }, [
              _c(
                "div",
                { staticClass: "checkout-view" },
                [
                  _c("SPopUp", {
                    staticClass: "checkoutError",
                    attrs: {
                      isOpen: _vm.errorPopUp,
                      sText: _vm.$t("label.teams.selected.errorPopUp"),
                      isValid: !_vm.errorPopUp,
                    },
                    on: { close: _vm.closePopUp },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "checkout-team" },
                    _vm._l(_vm.arrayStemdoers, function (stemdoer) {
                      return _c(
                        "div",
                        { key: stemdoer.id, attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "pa-0 mb-4" },
                            [
                              _c("SCardStemdoer", {
                                attrs: {
                                  stemdoer: stemdoer,
                                  rate: stemdoer.rate,
                                  cardOptions: _vm.stemdoerCardOptions,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.isClient && _vm.totalHourlyCost > 0
                    ? _c("div", { staticClass: "checkout-info" }, [
                        _c("div", { staticClass: "totalCost" }, [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("label.teams.selected.teamCostHour")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("h2", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$n(
                                    _vm.totalHourlyCost /
                                      _vm.arrayStemdoers.length,
                                    "decimal"
                                  ) + "€"
                                ) +
                                "/h\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "checkout-totalCost" }, [
                          _c(
                            "div",
                            {
                              staticClass: "totalCost-mini",
                              staticStyle: { "margin-right": "8px" },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("label.teams.selected.teamCostDay")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$n(_vm.totalDailyCost, "decimal") + "€"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "totalCost-mini",
                              staticStyle: { "margin-left": "8px" },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("label.teams.selected.teamCostMonth")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$n(_vm.totalDailyCost * 20, "decimal") +
                                      "€"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.teamRequestStatus === null ||
                        _vm.teamRequestStatus === _vm.RequestStatus.SAVED
                          ? _c(
                              "div",
                              { staticClass: "checkout-buttons" },
                              [
                                _c("SButton", {
                                  attrs: {
                                    btnStyle: "primary",
                                    sText: "entity.action.request",
                                    action: _vm.openBlockTeamModal,
                                  },
                                }),
                                _vm._v(" "),
                                _c("SButton", {
                                  staticStyle: { "margin-top": "16px" },
                                  attrs: {
                                    btnStyle: "secondary",
                                    sText: "label.teams.selected.saveProposal",
                                    action: _vm.openProposalTeamModal,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm.teamRequestStatus ===
                            _vm.RequestStatus.VALIDATED
                          ? _c(
                              "div",
                              { staticClass: "checkout-buttons" },
                              [
                                _c("SButton", {
                                  attrs: {
                                    btnStyle: "primary",
                                    sText: "requests.info-box.pending.accept",
                                    action: _vm.approveTeamRequest,
                                  },
                                }),
                                _vm._v(" "),
                                _c("SButton", {
                                  staticStyle: { "margin-top": "16px" },
                                  attrs: {
                                    btnStyle: "secondary",
                                    sText: "requests.info-box.pending.send-msg",
                                    action: () => {},
                                    disabled: true,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "checkout-chart" }, [
                          _c("span", { staticClass: "title-span" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("label.teams.selected.averageSkill")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          this.loaded
                            ? _c(
                                "div",
                                {
                                  ref: "chartSelected",
                                  staticClass: "chart-container",
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("SRadarStemdoersTechs", {
                                    attrs: { stemdoerList: _vm.arrayStemdoers },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "checkout-footprint" }, [
                          _c("span", { staticClass: "title-span" }, [
                            _vm._v(
                              _vm._s(_vm.$t("label.teams.selected.footprintLb"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "svg",
                              {
                                staticClass: "circle-container",
                                attrs: {
                                  width: "134",
                                  height: "134",
                                  viewBox: "0 0 134 134",
                                  fill: "none",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  { attrs: { transform: "scale(1,-1)" } },
                                  [
                                    _c("circle", {
                                      attrs: {
                                        cx: "67",
                                        cy: "-67",
                                        r: "56",
                                        fill: "#F9F9F9",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: {
                                        cx: "67",
                                        cy: "-67",
                                        r: "66",
                                        stroke: "#DBDBE0",
                                        "stroke-width": "2",
                                        "stroke-linejoin": "round",
                                        "stroke-dasharray": "2 2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      staticClass: "progress-ring__circle",
                                      style: _vm.footprintCircle(this.footp),
                                      attrs: {
                                        stroke: "url(#paint0_linear_447_20326)",
                                        "stroke-width": "3",
                                        "stroke-linecap": "round",
                                        transform: "rotate(-90, 67, -67)",
                                        fill: "transparent",
                                        r: "66",
                                        cx: "67",
                                        cy: "-67",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id: "paint0_linear_447_20326",
                                          x1: "10.6688",
                                          y1: "-120.6",
                                          x2: "132.842",
                                          y2: "-112.519",
                                          gradientUnits: "userSpaceOnUse",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: { "stop-color": "#4237FF" },
                                        }),
                                        _vm._v(" "),
                                        _c("stop", {
                                          attrs: {
                                            offset: "1",
                                            "stop-color": "#9747FF",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "foreignObject",
                                      {
                                        attrs: {
                                          x: "0",
                                          y: "-134",
                                          width: "134",
                                          height: "134",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "footprint-info" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "footprint-card" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-location",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        this.locationTeam
                                                      ) + " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-value",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          this.footp ==
                                                            undefined
                                                            ? 6
                                                            : this.footp
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-tco",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          tCO"
                                                    ),
                                                    _c("sub", [_vm._v("2")]),
                                                    _vm._v(
                                                      "eq/" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "label.teams.selected.footprintYear"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "svg",
                              {
                                staticClass: "circle-container",
                                attrs: {
                                  width: "134",
                                  height: "134",
                                  viewBox: "0 0 134 134",
                                  fill: "none",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  { attrs: { transform: "scale(1,-1)" } },
                                  [
                                    _c("circle", {
                                      attrs: {
                                        cx: "67",
                                        cy: "-67",
                                        r: "56",
                                        fill: "#F9F9F9",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: {
                                        cx: "67",
                                        cy: "-67",
                                        r: "66",
                                        stroke: "#DBDBE0",
                                        "stroke-width": "2",
                                        "stroke-linejoin": "round",
                                        "stroke-dasharray": "2 2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      staticClass: "progress-ring__circle",
                                      style: _vm.footprintCircle(
                                        this.arrayStemdoers.length * 12
                                      ),
                                      attrs: {
                                        stroke: "url(#paint0_linear_447_20326)",
                                        "stroke-width": "3",
                                        "stroke-linecap": "round",
                                        transform: "rotate(-90, 67, -67)",
                                        fill: "transparent",
                                        r: "66",
                                        cx: "67",
                                        cy: "-67",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id: "paint0_linear_447_20326",
                                          x1: "10.6688",
                                          y1: "-120.6",
                                          x2: "132.842",
                                          y2: "-112.519",
                                          gradientUnits: "userSpaceOnUse",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: { "stop-color": "#4237FF" },
                                        }),
                                        _vm._v(" "),
                                        _c("stop", {
                                          attrs: {
                                            offset: "1",
                                            "stop-color": "#9747FF",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "foreignObject",
                                      {
                                        attrs: {
                                          x: "0",
                                          y: "-134",
                                          width: "134",
                                          height: "134",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "footprint-info" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "footprint-card" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-location",
                                                  },
                                                  [_vm._v("Madrid")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-value",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          ~ " +
                                                        _vm._s(
                                                          this.arrayStemdoers
                                                            .length * 12
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-tco",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          tCO"
                                                    ),
                                                    _c("sub", [_vm._v("2")]),
                                                    _vm._v(
                                                      "eq/" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "label.teams.selected.footprintYear"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalVertical",
          attrs: {
            size: "lg",
            id: "user-details",
            "hide-footer": "",
            lazy: "",
          },
          on: {
            show: function ($event) {
              return _vm.onModalShow()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c(
                    "span",
                    { attrs: { "data-cy": "userDetails", id: "user-detail" } },
                    [
                      _c("div", { staticClass: "centerPDF" }, [
                        _c("img", {
                          staticClass: "minilogo",
                          attrs: { src: "/content/images/emini.png", alt: "" },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.nameSelected) +
                            "\n          "
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btnPDF",
                            on: {
                              click: function ($event) {
                                return _vm.generatePDF()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "fa-solid fa-file-pdf" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("show-detail", {
            attrs: { people: this.people, peopleImg: this.peopleImg },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("SModalTeamRequestConfirmation", {
        attrs: {
          teamRequest: _vm.teamRequest,
          isOpen: _vm.isOpenBlockTeamModal,
          onClose: _vm.closeBlockTeamModal,
          action: _vm.onClickFormModal,
          customProps: _vm.customPropsModal,
        },
      }),
      _vm._v(" "),
      _c("SModalStemdoerNotAvailable", {
        attrs: {
          stemdoers: _vm.arrayStemdoersNotAvailable,
          isOpen: _vm.stemdoersNotAvailable,
          onClose: _vm.closeStemdoersNotAvailableModal,
        },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "finishBlock",
          staticStyle: {
            width: "100%",
            height: "100%",
            background: "white",
            "box-shadow": "0px 2px 8px #dbdbe0",
            "border-radius": "4px",
            padding: "20px",
            "text-align": "center",
          },
          attrs: { id: "finishBlock", "no-close-on-backdrop": "" },
          on: {
            hide: function ($event) {
              return _vm.closeDialog()
            },
            show: function ($event) {
              return _vm.onModalShow()
            },
          },
        },
        [
          _c("span", {
            attrs: { slot: "modal-title" },
            domProps: { textContent: _vm._s(_vm.modalTitle) },
            slot: "modal-title",
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "16px", "margin-top": "-16px" } },
            [
              _c("span", {
                staticClass: "modal-finish",
                staticStyle: { display: "block", "margin-bottom": "22px" },
                domProps: { textContent: _vm._s(_vm.modalFinish) },
              }),
              _vm._v(" "),
              _c("SButton", {
                staticStyle: {
                  "font-size": "16px !important",
                  "font-weight": "700 !important",
                },
                attrs: {
                  btnStyle: "primary",
                  sText: "label.teams.selected.understood",
                  action: () => {
                    _vm.closeDialog()
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          class: { "bg-white": true, "modal-open": _vm.dialog },
          attrs: { "max-width": "70%" },
          on: { input: () => _vm.resetOverflow() },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm.dialog
            ? _c("StemdoerDetail", {
                attrs: {
                  stemdoer: _vm.stemdoerSelected,
                  closeDialog: () => {
                    _vm.closeDetailDialog()
                  },
                  rates: _vm.rate,
                  onAddToCart: () => {},
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }