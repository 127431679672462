import { render, staticRenderFns } from "./FilterTag.vue?vue&type=template&id=fb51c0d0&scoped=true"
import script from "./filterTag.ts?vue&type=script&lang=ts&external"
export * from "./filterTag.ts?vue&type=script&lang=ts&external"
import style0 from "./FilterTag.vue?vue&type=style&index=0&id=fb51c0d0&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb51c0d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fb51c0d0')) {
      api.createRecord('fb51c0d0', component.options)
    } else {
      api.reload('fb51c0d0', component.options)
    }
    module.hot.accept("./FilterTag.vue?vue&type=template&id=fb51c0d0&scoped=true", function () {
      api.rerender('fb51c0d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/sections/shared/explore/top-filter/filter-tag-list/filter-tag/FilterTag.vue"
export default component.exports