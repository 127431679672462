import { Module } from 'vuex';
import { TeamRequestsStateStorable, teamRequests } from './manager/teamRequestsPageStore';

export interface ManagerStateStorable {
  teamRequests: TeamRequestsStateStorable;
}

export const manager: Module<ManagerStateStorable, any> = {
  namespaced: true,
  modules: {
    teamRequests
  }
};
