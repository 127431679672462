var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 pl-0 pt-0 pb-0",
      attrs: { id: "team-requestsfilter", fluid: "" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex align-center filter-container" },
        [
          _c(
            "v-row",
            { staticClass: "d-flex align-center p-2" },
            [
              _c("v-col", {
                staticClass: "text-left p-0 pl-4",
                attrs: { cols: "12" },
              }),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "p-2", attrs: { cols: "2" } },
                [
                  _c("SSelectorMultiple", {
                    attrs: {
                      defaultSelectedItemsIds: _vm.storedCompaniesSelected,
                      placeholder: _vm.$t("requests-filter-list.filter.client"),
                      items: _vm.companyItems,
                      onChange: _vm.onCompanyChange,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "p-2", attrs: { cols: "2" } },
                [
                  _c("SSelectorMultiple", {
                    attrs: {
                      defaultSelectedItemsIds: _vm.storedStatusSelected,
                      placeholder: _vm.$t(
                        "requests-filter-list.filter.status.title"
                      ),
                      items: _vm.statusItems,
                      onChange: _vm.onStatusChange,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "p-2 pl-4 pr-4", attrs: { cols: "8" } },
                [
                  _c("SSearch", {
                    attrs: {
                      defaultText: _vm.storedSearch,
                      placeholder: _vm.$t("requests-filter-list.filter.search"),
                      onChange: _vm.onSearch,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }