var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex justify-content-end align-items-end" },
    [
      _c(
        "v-btn",
        {
          staticClass: "btnPDF",
          attrs: { loading: _vm.isLoading, disabled: _vm.isLoading, fab: "" },
          on: {
            click: function ($event) {
              return _vm.startPdfGeneration()
            },
          },
        },
        [
          _c("img", {
            staticClass: "iconPDF",
            attrs: {
              src: "/content/svgs/header/external-link.svg",
              width: "24px",
              height: "24px",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }