import { computed, defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
  props: {
    itemsPerPage: {
      type: Number,
      required: true
    },
    totalItems: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const page = ref(1);
    const totalPages = computed(() => {
      return Math.ceil(props.totalItems / props.itemsPerPage);
    });

    const onChange = (newPage: number) => {
      page.value = newPage;
      props.onPageChange(newPage);
    };

    return {
      page,
      totalPages,
      onChange
    };
  }
});
