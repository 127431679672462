import { defineComponent, PropType, computed, ref, watch, onMounted } from 'vue';
import SSelector from '@/components/stemdo-components/s-selector/SSelector.vue';
import SSearch from '@/components/stemdo-components/s-search/SSearch.vue';
import { TeamRequests } from '@/shared/model/team-request.model';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { useI18N } from '@/plugins/i18n';
import SSelectorMultiple from '@/components/stemdo-components/s-selector-multiple/SSelectorMultiple.vue';
import { useStore } from '@/plugins/vuex';

export default defineComponent({
  props: {
    teamRequests: {
      type: Array as PropType<TeamRequests>,
      required: true
    },
    onSearch: {
      type: Function,
      required: true
    },
    onCompanyChange: {
      type: Function,
      required: true
    },
    onStatusChange: {
      type: Function,
      required: true
    }
  },
  components: {
    SSelector,
    SSelectorMultiple,
    SSearch
  },
  setup(props) {
    const store = useStore();
    const i18n = useI18N();
    const storedCompaniesSelected = ref<string[]>(undefined);
    const storedStatusSelected = ref<string[]>(undefined);
    const storedSearch = ref<string>(undefined);

    onMounted(() => {
      const storedFiltes = store.getters['pagesStore/manager/teamRequests/filters'];
      storedCompaniesSelected.value = storedFiltes.company;
      storedStatusSelected.value = storedFiltes.status;
      storedSearch.value = storedFiltes.search;
    });

    const companyItems = computed(() => {
      const companiesSet = new Set();
      companiesSet.add({
        id: 'VIEW_ALL',
        desc: i18n.t('requests-filter-list.filter.status.all') as string
      });
      props.teamRequests.forEach(request => {
        if (request.client && request.client.company) {
          companiesSet.add({
            id: request.client.company.id,
            desc: request.client.company.name
          });
        }
      });
      return Array.from(companiesSet);
    });

    const statusItems = computed(() => {
      const statusSet = new Set();
      statusSet.add({
        id: 'VIEW_ALL',
        desc: i18n.t('requests-filter-list.filter.status.all') as string
      });
      Object.values(RequestStatus).forEach(status => {
        let translation = '';
        switch (status) {
          case RequestStatus.ON_GOING:
            translation = i18n.t('requests-filter-list.filter.status.approved') as string;
            break;
          case RequestStatus.PENDING:
            translation = i18n.t('requests-filter-list.filter.status.pending') as string;
            break;
          case RequestStatus.VALIDATED:
            translation = i18n.t('requests-filter-list.filter.status.validated') as string;
            break;
          case RequestStatus.CANCELLED_BY_MANAGER:
            translation = i18n.t('requests-filter-list.filter.status.rejected') as string;
            break;
          case RequestStatus.CANCELLED_BY_CLIENT:
            return;
          case RequestStatus.SAVED:
            translation = i18n.t('requests-filter-list.filter.status.saved') as string;
            break;
        }
        statusSet.add({
          id: status,
          desc: translation
        });
      });
      return Array.from(statusSet);
    });

    return {
      storedCompaniesSelected,
      storedStatusSelected,
      storedSearch,
      companyItems,
      statusItems
    };
  }
});
