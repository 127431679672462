import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter } from '@/plugins/router';
import { useStore } from '@/plugins/vuex';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';

export default defineComponent({
  components: {
    SAvatarStemdoer
  },
  props: {
    teamRequestId: {
      type: String,
      required: false
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const lastStemdoer = ref();
    const isAlertVisible = ref(false);
    const listOfAlerts = ref<string[]>([]);
    const isClient = store.getters.account?.authorities.includes('ROLE_CLIENT') ?? false;
    const isManager = store.getters.account?.authorities.includes('ROLE_GESTOR') ?? false;
    let timeOutId = null;
    const timeOut = ref(3000);
    const isClientSelected = computed(() => {
      const clientSelected = store.getters['cartStore/clientSelected'];
      if (isManager === true) {
        return clientSelected !== null && clientSelected !== undefined;
      }
      return true;
    });
    const cartTotalItems = computed(() => {
      return store.getters['cartStore/cartTotalItems'];
    });

    const goToCheckout = () => {
      const routeName = isManager ? 'checkoutManager' : 'checkoutClient';
      if (props.teamRequestId) {
        router.push({ name: routeName, query: { tr: props.teamRequestId } });
      } else {
        router.push({ name: routeName });
      }
    };

    watch(
      () => {
        return cartTotalItems;
      },
      (newVal: any, oldVal: any) => {
        if (newVal !== oldVal) {
          lastStemdoer.value = store.getters['cartStore/cartItems'][newVal - 1];
          isAlertVisible.value = true;
          listOfAlerts.value.push(lastStemdoer.value);
          timeOut.value = 3000;
          if (timeOutId) {
            clearTimeout(timeOutId);
          }
          timeOutId = setTimeout(() => {
            isAlertVisible.value = false;
          }, timeOut.value);
        }
      }
    );

    return { isClientSelected, cartTotalItems, lastStemdoer, isAlertVisible, goToCheckout };
  }
});
