import { computed, defineComponent, inject, PropType, ref, watch } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import PendingAndUserNotModifiedGroup from './groups/manager/pending-and-user-not-modified-group/PendingAndUserNotModifiedGroup.vue';
import PendingAndUserModifiedGroup from './groups/manager/pending-and-user-modified-group/PendingAndUserModifiedGroup.vue';
import ManagerAndValidatedGroup from './groups/manager/validated-group/ValidatedGroup.vue';
import ClientAndValidatedGroup from './groups/client/validated-group/ValidatedGroup.vue';
import SavedAndNotAllStemdoersAvailableGroup from './groups/client/saved-and-not-all-stemdoers-available-group/SavedAndNotAllStemdoersAvailableGroup.vue';
import ClientAndSavedGroup from './groups/client/saved-group/SavedGroup.vue';
import ClientAndValidatedAndNotAllStemdoersAvailableGroup from './groups/client/validated-and-not-all-stemdoers-available-group/ValidatedAndNotAllStemdoersAvailableGroup.vue';
import TRValidatedModal from './modal/manager/trValidated/TRValidatedModal.vue';
import TRCancelledModal from './modal/manager/trCancelled/TRCancelledModal.vue';
import SendProposalModal from './modal/manager/sendProposal/SendProposalModal.vue';
import SavedToPendingTRModal from './modal/client/savedToPendingTR/SavedToPendingTRModal.vue';
import SavedTROnlyAvailableStemdoersModal from './modal/client/savedToPendingTROnlyAvailableStemdoers/SavedTROnlyAvailableStemdoersModal.vue';
import NewProposalModal from './modal/manager/newProposal/NewProposalModal.vue';
import AcceptTRModal from './modal/client/acceptTR/AcceptTRModal.vue';
import AcceptTROnlyAvailableStemdoersModal from './modal/client/acceptTROnlyAvailableStemdoers/AcceptTROnlyAvailableStemdoersModal.vue';
import CheckCartSaveTrModal from './modal/client/checkCartSaveTr/CheckCartSaveTrModal.vue';
import { TeamRequest } from '@/shared/model/team-request.model';
import { ExtendedTeamRequestStatus } from '../enum/extendedTeamRequestStatus';

export enum ButtonGroupModals {
  TR_VALIDATED = 'TR_VALIDATED',
  TR_CANCELLED = 'TR_CANCELLED',
  SEND_PROPOSAL = 'SEND_PROPOSAL',
  SEND_PROPOSAL_ONLY_AVAILABLE = 'SEND_PROPOSAL_ONLY_AVAILABLE',
  NEW_PROPOSAL = 'NEW_PROPOSAL',
  SAVED_TO_PENDING_TR = 'SAVED_TO_PENDING_TR',
  SAVED_TO_PENDING_TR_ONLY_AVAILABLES = 'SAVED_TO_PENDING_TR_ONLY_AVAILABLES',
  ACCEPT_TR = 'ACCEPT_TR',
  ACCEPT_TR_ONLY_AVAILABLES = 'ACCEPT_TR_ONLY_AVAILABLES',
  CHECK_CART_SAVE_TR = 'CHECK_CART_SAVE_TR'
}

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    teamRequestStatus: {
      type: String as PropType<ExtendedTeamRequestStatus>
    },
    refreshTeamRequest: {
      type: Function,
      required: true
    },
    onSuccess: {
      type: Function,
      required: true
    },
    onError: {
      type: Function,
      required: true
    }
  },
  components: {
    SButton,
    PendingAndUserNotModifiedGroup,
    PendingAndUserModifiedGroup,
    ManagerAndValidatedGroup,
    ClientAndValidatedGroup,
    ClientAndValidatedAndNotAllStemdoersAvailableGroup,
    ClientAndSavedGroup,
    TRValidatedModal,
    TRCancelledModal,
    SendProposalModal,
    NewProposalModal,
    SavedToPendingTRModal,
    SavedTROnlyAvailableStemdoersModal,
    AcceptTRModal,
    AcceptTROnlyAvailableStemdoersModal,
    CheckCartSaveTrModal,
    SavedAndNotAllStemdoersAvailableGroup
  },
  setup(props) {
    const isTRValidatedModalOpen = ref(false);
    const isTRCancelledModalOpen = ref(false);
    const isSendProposalModalOpen = ref(false);
    const isNewProposalModalOpen = ref(false);
    const isSavedToPendingTRModalOpen = ref(false);
    const isAcceptTRModalOpen = ref(false);
    const isCheckCartSaveTrModalOpen = ref(false);
    const isSavedToPendingTROnlyAvailablesModalOpen = ref(false);
    const isAcceptTeamRequestOnlyAvailableModalOpen = ref(false);

    const closeModal = (type: ButtonGroupModals) => {
      switch (type) {
        case ButtonGroupModals.TR_VALIDATED:
          isTRValidatedModalOpen.value = false;
          break;
        case ButtonGroupModals.TR_CANCELLED:
          isTRCancelledModalOpen.value = false;
          break;
        case ButtonGroupModals.SEND_PROPOSAL:
          isSendProposalModalOpen.value = false;
          break;
        case ButtonGroupModals.NEW_PROPOSAL:
          isNewProposalModalOpen.value = false;
          break;
        case ButtonGroupModals.SAVED_TO_PENDING_TR:
          isSavedToPendingTRModalOpen.value = false;
          break;
        case ButtonGroupModals.SAVED_TO_PENDING_TR_ONLY_AVAILABLES:
          isSavedToPendingTROnlyAvailablesModalOpen.value = false;
          break;
        case ButtonGroupModals.ACCEPT_TR:
          isAcceptTRModalOpen.value = false;
          break;
        case ButtonGroupModals.ACCEPT_TR_ONLY_AVAILABLES:
          isAcceptTeamRequestOnlyAvailableModalOpen.value = false;
          break;
        case ButtonGroupModals.CHECK_CART_SAVE_TR:
          isCheckCartSaveTrModalOpen.value = false;
          break;
        default:
          throw new Error('Unknown modal type');
      }
    };

    const openModal = (type: ButtonGroupModals) => {
      switch (type) {
        case ButtonGroupModals.TR_VALIDATED:
          isTRValidatedModalOpen.value = true;
          break;
        case ButtonGroupModals.TR_CANCELLED:
          isTRCancelledModalOpen.value = true;
          break;
        case ButtonGroupModals.SEND_PROPOSAL:
          isSendProposalModalOpen.value = true;
          break;
        case ButtonGroupModals.NEW_PROPOSAL:
          isNewProposalModalOpen.value = true;
          break;
        case ButtonGroupModals.SAVED_TO_PENDING_TR:
          isSavedToPendingTRModalOpen.value = true;
          break;
        case ButtonGroupModals.SAVED_TO_PENDING_TR_ONLY_AVAILABLES:
          isSavedToPendingTROnlyAvailablesModalOpen.value = true;
          break;
        case ButtonGroupModals.ACCEPT_TR:
          isAcceptTRModalOpen.value = true;
          break;
        case ButtonGroupModals.ACCEPT_TR_ONLY_AVAILABLES:
          isAcceptTeamRequestOnlyAvailableModalOpen.value = true;
          break;
        case ButtonGroupModals.CHECK_CART_SAVE_TR:
          isCheckCartSaveTrModalOpen.value = true;
          break;
        default:
          throw new Error('Unknown modal type');
      }
    };

    const currentComponent = computed(() => {
      switch (props.teamRequestStatus) {
        case ExtendedTeamRequestStatus.MANAGER_AND_PENDING_AND_USER_NOT_MODIFIED:
          return 'PendingAndUserNotModifiedGroup';
        case ExtendedTeamRequestStatus.MANAGER_AND_PENDING_AND_USER_MODIFIED:
          return 'PendingAndUserModifiedGroup';
        case ExtendedTeamRequestStatus.MANAGER_AND_VALIDATED_AND_USER_NOT_MODIFIED:
          return 'ManagerAndValidatedGroup';
        case ExtendedTeamRequestStatus.MANAGER_AND_VALIDATED_AND_USER_MODIFIED:
          return 'ManagerAndValidatedGroup';
        case ExtendedTeamRequestStatus.CLIENT_AND_SAVED:
          return 'ClientAndSavedGroup';
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_MODIFIED_BY_MANAGER:
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_NOT_MODIFIED_BY_MANAGER:
          return 'ClientAndValidatedGroup';
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE:
          return 'ClientAndValidatedAndNotAllStemdoersAvailableGroup';
        case ExtendedTeamRequestStatus.CLIENT_AND_SAVED_AND_NOT_ALL_STEMDOERS_AVAILABLE:
          return 'SavedAndNotAllStemdoersAvailableGroup';
        default:
          return null;
      }
    });

    const isDisabled = computed(() => {
      return props.teamRequestStatus === ExtendedTeamRequestStatus.MANAGER_AND_VALIDATED_AND_USER_NOT_MODIFIED;
    });

    return {
      isTRValidatedModalOpen,
      isTRCancelledModalOpen,
      isSendProposalModalOpen,
      isCheckCartSaveTrModalOpen,
      isNewProposalModalOpen,
      isAcceptTRModalOpen,
      isSavedToPendingTRModalOpen,
      isSavedToPendingTROnlyAvailablesModalOpen,
      isAcceptTeamRequestOnlyAvailableModalOpen,
      currentComponent,
      isDisabled,
      openModal,
      closeModal
    };
  }
});
