import { Authority } from '@/shared/security/authority';
import StemdoerPage from '@/pages/shared/stemdoer/StemdoerPage.vue';

export default [
  {
    path: '/stemdoer/:id',
    name: 'stemdoerById',
    component: StemdoerPage,
    props: (route: { params: { id: any } }) => ({ stemdoerId: route.params.id } as { stemdoerId: string }),
    meta: { authorities: [Authority.CLIENT, Authority.GESTOR] }
  }
];
