import { computed, defineComponent, ref } from 'vue';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import { useI18N } from '@/plugins/i18n';

export default defineComponent({
  props: {
    candidateSelected: {
      type: Object,
      required: true
    }
  },
  components: {
    SAvatarStemdoer
  },
  setup(props) {
    const i18n = useI18N();

    const getInitDateFromStemdoerTeams = stemdoerTeam => {
      const today = new Date();
      for (let i = 0; i < stemdoerTeam.length; i++) {
        const initDate = new Date(stemdoerTeam[i].initDate);
        const endDate = stemdoerTeam[i].endDate === null ? null : new Date(stemdoerTeam[i].endDate);
        if (initDate <= today && (endDate === null || endDate >= today)) {
          const day = String(initDate.getDate()).padStart(2, '0');
          const month = String(initDate.getMonth() + 1).padStart(2, '0');
          const year = initDate.getFullYear();
          return `${day}/${month}/${year}`;
        }
      }
      return null;
    };

    return {
      getInitDateFromStemdoerTeams
    };
  }
});
