import FilterGroupAnd from './group/filter-group-and.model';
import FilterGroupOr from './group/filter-group-or.model';
import FilterGroup from './group/filter-group.model';
import Filter from './filter.model';

class Filters {
  rootGroup: FilterGroup;

  constructor(rootGroupLogic: 'AND' | 'OR') {
    if (rootGroupLogic === 'AND') {
      this.rootGroup = new FilterGroupAnd('root');
      return;
    }
    if (rootGroupLogic === 'OR') {
      this.rootGroup = new FilterGroupOr('root');
      return;
    }
    throw new Error('Invalid logic. Use AND or OR');
  }
  add = (filter: Filter) => {
    this.rootGroup.add(filter);
  };

  filter = (entities: any[]) => {
    return entities.filter(entity => this.rootGroup.filter(entity));
  };
}
export default Filters;
