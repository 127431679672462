import Filter from '../filter.model';

abstract class FilterGroup implements Filter {
  id: string;
  filters: Filter[];
  logic: 'AND' | 'OR';

  constructor(id: string) {
    this.id = id;
    this.filters = [];
  }

  abstract filter(entity: any): boolean;

  abstract toString(): string;

  add(filter: Filter): void {
    const index = this.filters.findIndex(f => f.id === filter.id);
    if (index !== -1) {
      this.filters.splice(index, 1, filter);
    } else {
      this.filters.push(filter);
    }
  }
}
export default FilterGroup;
