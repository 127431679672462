<template>
  <Checkout :teamRequestId="teamRequestId" />
</template>

<script setup>
import Checkout from '@/sections/shared/checkout/Checkout.vue';
import { useRouter } from '@/plugins/router';

const router = useRouter();
const teamRequestId = router.currentRoute.query['tr']?.toString();
</script>
