<template>
  <Stemdoer :stemdoerId="stemdoerId" />
</template>

<script setup>
import Stemdoer from '@/sections/shared/stemdoer/Stemdoer.vue';
import { useRouter } from '@/plugins/router';

const router = useRouter();
const stemdoerId = router.currentRoute.params['id'].toString();
</script>
