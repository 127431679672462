/* eslint-disable prettier/prettier */
import { PropType, computed, defineComponent } from 'vue';
import Filter from '../../../model/Filter';
import { useStore } from '@/plugins/vuex';

export default defineComponent({
  props: {
    tag: {
      type: Object as PropType<Filter>,
      required: true
    }
  },
  setup({ tag }) {
    const store = useStore();
    const tagText = computed(() => {
      const text = tag.toLabelText();
      return text;
    });

    const handleClose = () => {
      store.commit('filterStore/removeFilter', tag);
    };

    return { tagText, handleClose };
  }
});
