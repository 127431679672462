import FilterGroup from './filter-group.model';

class FilterGroupOr extends FilterGroup {
  constructor(id: string) {
    super(id);
    this.logic = 'OR';
  }

  filter(entity: any): boolean {
    for (const filter of this.filters) {
      if (filter.filter(entity)) {
        return true;
      }
    }
    return false;
  }

  toString(): string {
    throw new Error('Method not implemented.');
  }
}
export default FilterGroupOr;
