import { defineComponent, onMounted, ref, watch } from 'vue';
import ExperienceLevelCard from './experience-level-card/ExperienceLevelCard.vue';
import Filters, { FilterFields } from '../../../model/Filters';
import { useStore } from '@/plugins/vuex';

export default defineComponent({
  components: {
    ExperienceLevelCard
  },
  props: {
    filterField: String,
    action: {
      type: Function
    },
    tempFilters: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const selected = ref(undefined);
    const experienceLevelOptions = {
      explore: 0,
      strategist: 1,
      visionary: 2
    };

    onMounted(() => {
      if (props.filterField) {
        selected.value = getComponentState();
      }
    });

    const handleChange = (optionValue: number) => {
      props.action(props.filterField, optionValue);
    };

    if (!props.tempFilters) {
      watch(
        () => store.getters['filterStore/filters'],
        (filters: Filters) => {
          selected.value = getComponentState();
        },
        { deep: true }
      );
    }

    if (props.tempFilters) {
      watch(
        () => store.getters['filterStore/tempFilters'],
        (filters: Filters) => {
          selected.value = getComponentState();
        },
        { deep: true }
      );
    }

    const getComponentState = (): number => {
      const payload = props.filterField as FilterFields;
      if (!props.tempFilters) {
        throw new Error('getCheckboxListState not implemented yet');
      }
      return store.getters['filterStore/getCheckboxListTempState'](payload);
    };

    return { experienceLevelOptions, selected, handleChange };
  }
});
