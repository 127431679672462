import { IClient } from '@/shared/model/client.model';
import { IVertical } from '@/shared/model/vertical.model';
import { ICertification } from '@/shared/model/certification.model';

import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { ITeamActivity } from './team-activity.model';
import { StemdoerRate, IStemdoerRate, StemdoerRates } from './stemdoer-rate.model';
import { IStemdoer, Stemdoers } from './stemdoer.model';
import { IMessage } from '@/shared/model/message.model';
import { ITRLogSummary, TRLogSummary } from './tr-log-summary.model';

export interface ITeamRequest {
  id?: string;
  name?: string | null;
  createdAt?: Date;
  initDate?: Date;
  endDate?: Date;
  english?: boolean | null;
  paramsUrl?: string | null;
  techPreferences?: string | null;
  jobDescription?: string | null;
  hourlyRate?: number | null;
  status?: RequestStatus | null;
  client?: IClient;
  clientId?: string;
  vertical?: IVertical | null;
  certifications?: ICertification[] | null;
  stemdoers?: IStemdoer[] | null;
  stemdoerRates?: IStemdoerRate[] | null;
  teamActivities?: ITeamActivity[] | null;
  validation?: boolean | null;
  messages?: IMessage[] | null;
  modifiedByManager?: boolean;
  logSummary?: ITRLogSummary;
}

export class TeamRequest implements ITeamRequest {
  id?: string;
  name?: string;
  createdAt?: Date;
  initDate?: Date;
  endDate?: Date;
  english?: boolean;
  paramsUrl?: string;
  techPreferences?: string;
  jobDescription?: string;
  hourlyRate?: number;
  status?: RequestStatus;
  client?: IClient;
  clientId?: string;
  vertical?: IVertical;
  certifications?: ICertification[];
  stemdoers?: IStemdoer[];
  stemdoerRates?: StemdoerRates;
  teamActivities?: ITeamActivity[];
  validation?: boolean;
  messages?: IMessage[];
  modifiedByManager?: boolean;
  logSummary?: TRLogSummary;

  constructor(data: ITeamRequest) {
    this.id = data?.id;
    this.name = data.name;
    this.createdAt = data.createdAt;
    this.initDate = data.initDate;
    this.endDate = data.endDate;
    this.english = data.english;
    this.paramsUrl = data.paramsUrl;
    this.techPreferences = data.techPreferences;
    this.jobDescription = data.jobDescription;
    this.hourlyRate = data.hourlyRate;
    this.status = data.status;
    this.client = data.client;
    this.clientId = data.clientId;
    this.vertical = data.vertical;
    this.certifications = data.certifications;
    this.stemdoers = data.stemdoers;
    this.stemdoerRates = StemdoerRates.from(data.stemdoerRates);
    this.teamActivities = data.teamActivities;
    this.validation = data.validation;
    this.messages = data.messages;
    this.modifiedByManager = data.modifiedByManager;
    this.logSummary = new TRLogSummary(data.logSummary);
  }

  static empty = () => {
    return new TeamRequest({
      name: null,
      createdAt: null,
      initDate: null,
      endDate: null,
      english: null,
      paramsUrl: null,
      techPreferences: null,
      jobDescription: null,
      hourlyRate: null,
      status: null,
      client: null,
      clientId: null,
      vertical: null,
      certifications: null,
      stemdoers: null,
      stemdoerRates: [],
      teamActivities: null,
      validation: null,
      messages: null,
      modifiedByManager: null
    });
  };

  removeNotAvailablesStemdoer = () => {
    this.stemdoerRates = StemdoerRates.from(
      this.stemdoerRates.filter(stemdoerRate => {
        return stemdoerRate.stemdoer.availability?.available === true;
      })
    );
  };

  areAllStemdoereAvailable: () => boolean = () => {
    return this.stemdoerRates.every(stemdoerRate => {
      return stemdoerRate.stemdoer.availability?.available === true;
    });
  };

  areAllStemdoereNotAvailable: () => boolean = () => {
    return this.stemdoerRates.every(stemdoerRate => {
      return stemdoerRate.stemdoer.availability.available === false;
    });
  };

  addStemdoers = (stemdoerRates: IStemdoerRate[]) => {
    stemdoerRates.forEach(stemdoerRate => {
      if (!this.stemdoerRates.some(sr => sr.stemdoer.id === stemdoerRate.stemdoer.id)) {
        this.stemdoerRates.push(new StemdoerRate(stemdoerRate));
      }
    });
  };

  setStemdoerRates = (stemdoers: Stemdoers) => {
    this.stemdoerRates = StemdoerRates.from(
      stemdoers.map(stemdoer => {
        return new StemdoerRate({ stemdoer, rate: stemdoer.rate });
      })
    );
  };

  addStemdoerRates = (stemdoers: Stemdoers) => {
    stemdoers.forEach(stemdoer => {
      if (!this.stemdoerRates.some(sr => sr.stemdoer.id === stemdoer.id)) {
        this.stemdoerRates.push(new StemdoerRate({ stemdoer, rate: stemdoer.rate }));
      }
    });
  };

  getStemdoers = (): Stemdoers => {
    return new Stemdoers(
      this.stemdoerRates.map(stemdoerRate => {
        return stemdoerRate.stemdoer;
      })
    );
  };

  removeStemdoerById = (id: string) => {
    this.stemdoerRates = StemdoerRates.from(
      this.stemdoerRates.filter(stemdoerRate => {
        return stemdoerRate.stemdoer.id !== id;
      })
    );
  };

  updateRate = (id: string, newRate: number) => {
    const stemdoerRate = this.stemdoerRates.find(sr => sr.stemdoer.id === id);
    if (stemdoerRate) {
      stemdoerRate.rate = newRate;
    }
  };

  calculateTotalRate = (): number => {
    return this.stemdoerRates.reduce((acc, sr) => {
      return acc + sr.rate;
    }, 0);
  };
}

export class TeamRequests extends Array<TeamRequest> {
  constructor(data: TeamRequest[]) {
    super(...data);
  }

  static empty = () => {
    return new TeamRequests([]);
  };

  static from = (data: TeamRequest[]): TeamRequests => {
    return new TeamRequests(data.map(teamRequest => new TeamRequest(teamRequest)));
  };

  static fromI = (data: ITeamRequest[]): TeamRequests => {
    return new TeamRequests(data.map(teamRequest => new TeamRequest(teamRequest)));
  };
}
