import { IAtocompleteMultipleRatingState } from '@/sections/shared/explore/top-filter/filter/s-autocomplete-multiple-rating/sAutocompleteMultipleRating';
import Filter, { IAtocompleteMultipleProps, IAtocompleteMultipleRatingProps, INumberValue, Operators } from './Filter';
import { IAtocompleteMultipleState } from '@/sections/shared/explore/top-filter/filter/s-autocomplete-multiple/sAutocompleteMultiple';
import { IRangeSliderStepsRateState } from '@/sections/shared/explore/top-filter/filter/s-range-slider-steps-rate/sRangeSliderStepsRate';
import { IAtocompleteState } from '@/sections/shared/explore/top-filter/filter/s-autocomplete/sAutocomplete';
import { IStandardFilter } from '@/shared/model/StandardFilter';

export enum FilterFields {
  Technology = 'tech',
  Competency = 'comp',
  SoftSkill = 'sk',
  Certification = 'cert',
  ExperienceLevel = 'exp',
  Rate = 'rate',
  Language = 'lang',
  Available = 'avail'
}

export enum UIFilterTypes {
  Autocomplete = 'Autocomplete',
  AutocompleteMutiple = 'AutocompleteMutiple',
  AutocompleteMutipleRating = 'AutocompleteMutipleRating',
  RangeSilderStesRate = 'RangeSilderStesRate',
  Checkbox = 'Checkbox',
  SwitchReverse = 'SwitchReverse',
  CheckboxList = 'CheckboxList'
}

export const UIFiltersDef = {
  [FilterFields.Technology]: UIFilterTypes.Autocomplete,
  [FilterFields.Competency]: UIFilterTypes.AutocompleteMutipleRating,
  [FilterFields.SoftSkill]: UIFilterTypes.AutocompleteMutiple,
  [FilterFields.Certification]: UIFilterTypes.AutocompleteMutiple,
  [FilterFields.Rate]: UIFilterTypes.RangeSilderStesRate,
  [FilterFields.Language]: UIFilterTypes.Checkbox,
  [FilterFields.Available]: UIFilterTypes.SwitchReverse,
  [FilterFields.ExperienceLevel]: UIFilterTypes.CheckboxList
};

export interface IAtocompleteMultipleRatingOperator extends Array<Operators> {
  length: 2;
  0: Operators;
  1: Operators;
}
export interface IAtocompleteMultipleRatingValue extends Array<INumberValue | number> {
  length: 2;
  0: INumberValue;
  1: number;
}

export default class Filters extends Array<Filter> {
  static empty = (): Filters => {
    return new Filters();
  };

  static defaulClearState = (): Filters => {
    const filters = new Filters();
    const filter1 = new Filter(FilterFields.Available, { operator: Operators.Equal, value: false });
    filters.add(filter1);
    return filters;
  };

  static defaulInitState = (): Filters => {
    const filters = new Filters();
    const filter1 = new Filter(FilterFields.Available, { operator: Operators.Equal, value: false });
    filters.add(filter1);
    return filters;
  };

  static clone = (sourceFilters: Filters): Filters => {
    const filters = new Filters();
    sourceFilters.forEach(f => {
      filters.push(Filter.clone(f));
    });
    return filters;
  };

  public getTotalFilters = (): number => {
    return this.length;
  };

  public add = (filter: Filter) => {
    this.push(filter);
  };

  public remove = (filter: Filter) => {
    const i = this.findIndex(f => f.getId() === filter.getId());
    if (i === -1) throw new Error(`Filter not found to remove: ${filter.getId()}`);
    this.splice(i, 1);
  };

  private removeByFieldAndValueId = (field: FilterFields, valueId?: string) => {
    if (UIFiltersDef[field] === UIFilterTypes.AutocompleteMutiple) {
      this.splice(
        this.findIndex(f => f.field === field && (f.props as IAtocompleteMultipleProps).value.id === valueId),
        1
      );
    } else if (UIFiltersDef[field] === UIFilterTypes.AutocompleteMutipleRating) {
      this.splice(
        this.findIndex(f => f.field === field && (f.props as IAtocompleteMultipleRatingProps).value[0].id === +valueId),
        1
      );
    } else if (
      UIFiltersDef[field] === UIFilterTypes.RangeSilderStesRate ||
      UIFiltersDef[field] === UIFilterTypes.Checkbox ||
      UIFiltersDef[field] === UIFilterTypes.SwitchReverse
    ) {
      this.splice(
        this.findIndex(f => f.field === field),
        1
      );
    } else if (UIFiltersDef[field] === UIFilterTypes.Autocomplete) {
      this.splice(
        this.findIndex(f => f.field === field),
        1
      );
    }
  };

  public updateByFieldAndValueId = (field: FilterFields, filter: Filter, valueId?: string) => {
    const i = this.getIndexByFieldAndValueId(field, valueId);
    if (i !== -1) {
      this.splice(i, 1, filter);
    } else {
      this.add(filter);
    }
  };

  public clearByField = (field: FilterFields) => {
    for (let i = this.length - 1; i >= 0; i--) {
      if (this[i].field === field) {
        this.splice(i, 1);
      }
    }
  };

  public clearAll = () => {
    this.splice(0, this.length);
  };

  public getFilterByFieldAndValueId = (field: FilterFields, valueId: string): Filter => {
    if (UIFiltersDef[field] === UIFilterTypes.AutocompleteMutiple) {
      return this.find(f => f.field === field && (f.props as IAtocompleteMultipleProps).value.id === valueId);
    } else if (UIFiltersDef[field] === UIFilterTypes.AutocompleteMutipleRating) {
      return this.find(f => f.field === field && (f.props as IAtocompleteMultipleRatingProps).value[0].id === +valueId);
    }
    throw new Error('This method not supported for this type of filter');
  };

  public getIndexByFieldAndValueId = (field: FilterFields, valueId?: string): number => {
    if (UIFiltersDef[field] === UIFilterTypes.AutocompleteMutiple) {
      return this.findIndex(f => f.field === field && (f.props as IAtocompleteMultipleProps).value.id === valueId);
    } else if (UIFiltersDef[field] === UIFilterTypes.Autocomplete) {
      return this.findIndex(f => f.field === field);
    } else if (UIFiltersDef[field] === UIFilterTypes.AutocompleteMutipleRating) {
      return this.findIndex(f => f.field === field && (f.props as IAtocompleteMultipleRatingProps).value[0].id === +valueId);
    } else if (
      UIFiltersDef[field] === UIFilterTypes.RangeSilderStesRate ||
      UIFiltersDef[field] === UIFilterTypes.Checkbox ||
      UIFiltersDef[field] === UIFilterTypes.CheckboxList ||
      UIFiltersDef[field] === UIFilterTypes.SwitchReverse
    ) {
      return this.findIndex(f => f.field === field);
    }
    throw new Error('This method not supported for this type of filter');
  };

  public getFiltersByField = (field: FilterFields): Filter[] => {
    return this.filter(f => f.field === field);
  };

  public getValuesByField = (field: FilterFields): any[] => {
    const filterValues = this.getFiltersByField(field).map(f => f.props.value);
    return filterValues;
  };

  public toStandardFilter = (): IStandardFilter[] => {
    const standardFilters: IStandardFilter[] = [];
    this.forEach((filter: Filter) => {
      const filterStandard = filter.toStandardFilter();
      filterStandard.forEach(item => standardFilters.push(item));
    });
    return standardFilters;
  };

  /********** AutoCompleteMultipleRating **********/

  public updateAutoCompleteMultipleRatingState = (field: FilterFields, newSelectedItem: IAtocompleteMultipleRatingState) => {
    if (UIFiltersDef[field] !== UIFilterTypes.AutocompleteMutipleRating) {
      throw new Error('This method is only for AutocompleteMutipleRating filters');
    }
    const selectedItems: IAtocompleteMultipleRatingState[] = this.getAutoCompleteMultipleRatingState(field);
    const isAlreadySelected = selectedItems.find(i => i.id === newSelectedItem.id);
    if (isAlreadySelected) {
      if (newSelectedItem.rating === 0) {
        this.removeByFieldAndValueId(field, String(isAlreadySelected.id));
        return;
      }
      const props: IAtocompleteMultipleRatingProps = {
        operator: [Operators.Equal, Operators.GreaterThanOrEqual],
        value: [{ id: +newSelectedItem.id, desc: newSelectedItem.desc }, newSelectedItem.rating]
      };
      const updatedFilter = new Filter(field, props);
      this.updateByFieldAndValueId(field, updatedFilter, String(newSelectedItem.id));
    } else {
      const props: IAtocompleteMultipleRatingProps = {
        operator: [Operators.Equal, Operators.GreaterThanOrEqual],
        value: [{ id: +newSelectedItem.id, desc: newSelectedItem.desc }, newSelectedItem.rating]
      };
      const filter = new Filter(field, props);
      this.add(filter);
    }
  };

  public getAutoCompleteMultipleRatingState = (field: FilterFields): IAtocompleteMultipleRatingState[] => {
    if (UIFiltersDef[field] !== UIFilterTypes.AutocompleteMutipleRating) {
      throw new Error('This method is only for AutocompleteMutipleRating filters');
    }
    const filters = this.getFiltersByField(field);
    return filters.map(f => f.toAtocompleteMultipleRatingState());
  };

  /********************/

  /********** AutoComplete **********/

  public updateAutoCompleteState = (field: FilterFields, newSelectedItem: IAtocompleteState) => {
    if (UIFiltersDef[field] !== UIFilterTypes.Autocomplete) {
      throw new Error('This method is only for Autocomplete filters');
    }

    const filter = new Filter(field, { operator: Operators.Equal, value: newSelectedItem });

    this.updateByFieldAndValueId(field, filter);
  };

  public getAutoCompleteState = (field: FilterFields): IAtocompleteState => {
    if (UIFiltersDef[field] !== UIFilterTypes.Autocomplete) {
      throw new Error('This method is only for Autocomplete filters');
    }
    const filters = this.getFiltersByField(field);
    if (filters.length === 0) return undefined;
    return filters[0].toAtocompleteState();
  };

  /********************/

  /********** AutoCompleteMultiple **********/

  public updateAutoCompleteMultipleState = (field: FilterFields, newSelectedItems: IAtocompleteMultipleState[]) => {
    if (UIFiltersDef[field] !== UIFilterTypes.AutocompleteMutiple) {
      throw new Error('This method is only for AutocompleteMutiple filters');
    }
    const oldSelectedItems: IAtocompleteMultipleState[] = this.getAutoCompleteMultipleState(field);
    if (newSelectedItems.length > oldSelectedItems.length) {
      newSelectedItems.forEach(item => {
        if (!oldSelectedItems.find(i => i.id === item.id)) {
          const filter = new Filter(field, { operator: Operators.Equal, value: item });
          this.add(filter);
        }
      });
    } else {
      oldSelectedItems.forEach(item => {
        if (!newSelectedItems.find(i => i.id === item.id)) {
          this.removeByFieldAndValueId(field, item.id);
        }
      });
    }
  };

  public getAutoCompleteMultipleState = (field: FilterFields): IAtocompleteMultipleState[] => {
    if (UIFiltersDef[field] !== UIFilterTypes.AutocompleteMutiple) {
      throw new Error('This method is only for AutocompleteMutiple filters');
    }
    const filters = this.getFiltersByField(field);
    return filters.map(f => f.toAtocompleteMultipleState());
  };

  /********************/

  /********** RangeSilderStesRate **********/

  public updateRangeSliderStepsRateState = (field: FilterFields, newRateRange: IRangeSliderStepsRateState) => {
    if (UIFiltersDef[field] !== UIFilterTypes.RangeSilderStesRate) {
      throw new Error('This method is only for RangeSilderStesRate filters');
    }

    const filter = new Filter(field, { operator: Operators.Between, value: newRateRange });

    const alreadyExist = this.getFiltersByField(field).length > 0;
    if (!alreadyExist) {
      this.add(filter);
      return;
    }
    this.updateByFieldAndValueId(field, filter);
  };

  public getRangeSilderStesRateState = (field: FilterFields): IRangeSliderStepsRateState => {
    if (UIFiltersDef[field] !== UIFilterTypes.RangeSilderStesRate) {
      throw new Error('This method is only for RangeSilderStesRate filters');
    }
    const filters = this.getFiltersByField(field);
    if (filters.length === 0) return [0, 0];

    return filters[0].toRangeSlderStepsRateState();
  };

  /********************/

  /********** Checkbox **********/

  public updateCheckboxState = (field: FilterFields, newVal: boolean) => {
    if (UIFiltersDef[field] !== UIFilterTypes.Checkbox) {
      throw new Error('This method is only for Checkbox filters');
    }

    const alreadyExist = this.getFiltersByField(field).length > 0;

    if (!alreadyExist && newVal) {
      const filter = new Filter(field, { operator: Operators.Equal, value: newVal });
      this.add(filter);
      return;
    }
    if (alreadyExist && !newVal) {
      const filter = new Filter(field, { operator: Operators.Equal, value: !newVal });
      this.remove(filter);
      return;
    }
  };

  public getCheckboxState = (field: FilterFields): boolean => {
    if (UIFiltersDef[field] !== UIFilterTypes.Checkbox) {
      throw new Error('This method is only for Checkbox filters');
    }
    const filters = this.getFiltersByField(field);
    if (filters.length === 0) return false;

    return filters[0].toCheckboxState();
  };

  /********************/

  /********** Checkbox List **********/

  public updateCheckboxListState = (field: FilterFields, newVal: number) => {
    if (UIFiltersDef[field] !== UIFilterTypes.CheckboxList) {
      throw new Error('This method is only for Checkbox List filters');
    }

    const currentFilter = this.getFiltersByField(field)[0];

    const filter = new Filter(field, { operator: Operators.Equal, value: newVal });
    if (!currentFilter) {
      this.add(filter);
    } else if (currentFilter && currentFilter.props.value === newVal) {
      this.remove(filter);
    } else if (currentFilter && currentFilter.props.value !== newVal) {
      this.updateByFieldAndValueId(field, filter);
    }
  };

  public getCheckboxListState = (field: FilterFields): number => {
    if (UIFiltersDef[field] !== UIFilterTypes.CheckboxList) {
      throw new Error('This method is only for Checkbox List filters');
    }
    const filters = this.getFiltersByField(field);
    if (filters.length === 0) return undefined;

    return filters[0].toCheckboxListState();
  };

  /********************/

  /********** Switch Reverse **********/

  public updateSwitchReverseState = (field: FilterFields, newVal: boolean) => {
    if (UIFiltersDef[field] !== UIFilterTypes.SwitchReverse) {
      throw new Error('This method is only for SwitchReverse filters');
    }

    const alreadyExist = this.getFiltersByField(field).length > 0;

    if (!alreadyExist && !newVal) {
      const filter = new Filter(field, { operator: Operators.Equal, value: newVal });
      this.add(filter);
      return;
    }
    if (alreadyExist && newVal) {
      const filter = new Filter(field, { operator: Operators.Equal, value: !newVal });
      this.remove(filter);
      return;
    }
  };

  public getSwitchReverseState = (field: FilterFields): boolean => {
    if (UIFiltersDef[field] !== UIFilterTypes.SwitchReverse) {
      throw new Error('This method is only for SwitchReverse filters');
    }
    const filters = this.getFiltersByField(field);
    if (filters.length === 0) return true;

    return filters[0].toSwitchReverseState();
  };

  /********************/
}
