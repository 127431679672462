import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { IStemdoer, Stemdoers } from '@/shared/model/stemdoer.model';
import { TeamRequest } from '@/shared/model/team-request.model';
import ResourceService from '@/shared/ResourceService.service';
import axios from 'axios';
import { useStore } from '@/plugins/vuex';
import { inject, ref, watch } from 'vue';
import { useI18N } from '@/plugins/i18n';

export default {
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const i18n = useI18N();
    const isLoading = ref(false);
    const resourceService: ResourceService = inject('resourceService');
    const teamRequest = ref<TeamRequest>(props.teamRequest);

    watch(
      () => props.teamRequest,
      newVal => {
        teamRequest.value = newVal;
      }
    );

    const getRadarData = (stemdoers: Stemdoers) => {
      const scoresGroupedByTechIds: { [key: string]: number[] } = {};
      const technologies = [];
      const averageScores = [];

      stemdoers.forEach(stemdoer => {
        stemdoer.technologyScores.forEach(techScore => {
          if (!scoresGroupedByTechIds[techScore.idTechnology]) {
            scoresGroupedByTechIds[techScore.idTechnology] = [];
          }
          scoresGroupedByTechIds[techScore.idTechnology].push(techScore.score);
        });
      });

      const techScoresArray = Object.entries(scoresGroupedByTechIds).map(([techId, scores]) => {
        const averageScore = scores.reduce((acc, score) => acc + score, 0) / scores.length;
        return {
          techId,
          averageScore: averageScore ? Math.round(averageScore * 10) / 10 : 0
        };
      });
      techScoresArray.sort((a, b) => b.averageScore - a.averageScore);
      const top5TechScores = techScoresArray.slice(0, 5);
      top5TechScores.forEach(({ techId, averageScore }) => {
        technologies.push(i18n.t(`technologyMaster.list.${techId}`).toString());
        averageScores.push(averageScore);
      });

      return { technologies: technologies, averageScores: averageScores };
    };

    const startPdfGeneration = async () => {
      isLoading.value = true;
      if (teamRequest.value.status !== 'ON_GOING') {
        teamRequest.value.removeNotAvailablesStemdoer();
      }
      const stemdoerIds = teamRequest.value.stemdoerRates.map(({ stemdoer }) => stemdoer.id).join(',');
      let footprint;
      try {
        footprint = await resourceService.getFootPrint(stemdoerIds);
        footprint = (Math.floor(Number(footprint) * 10) / 10).toString();
      } catch (error) {
        console.error('Error getting footprint', error);
      }

      const radarDataAndLabels = getRadarData(teamRequest.value.getStemdoers());
      const radarData = {
        menuStartDate: false,
        menuEndDate: false,
        loader: null,
        loading: false,
        clipboard: false,
        dialog: false,
        errorPopUp: false,
        isProposalTeam: false,
        labels: radarDataAndLabels.technologies,
        datasets: [
          {
            label: 'Media  de  Skills',
            backgroundColor: 'rgba(179,181,198,0.2)',
            borderColor: 'rgba(179,181,198,1)',
            pointBackgroundColor: 'rgba(179,181,198,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
            data: radarDataAndLabels.averageScores
          }
        ]
      };

      const pdfData = {
        footp: footprint || 6,
        paramId: teamRequest.value.id,
        dataRadar: radarData,
        isTeam: true,
        ratesList: {},
        stemdoerShowBadge: {},
        initDate: new Date(teamRequest.value.initDate).toLocaleDateString(),
        endDate: new Date(teamRequest.value.endDate).toLocaleDateString(),
        locale: store.getters['currentLanguage'],
        idClient: teamRequest.value.client.id,
        nameClient: teamRequest.value.client.name
      };

      const pdfDataJson = JSON.stringify(pdfData);
      resourceService.generatePDF(stemdoerIds, pdfDataJson);
      isLoading.value = false;
    };

    return {
      isLoading,
      startPdfGeneration
    };
  }
};
