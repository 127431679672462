import Vue from 'vue';
import { Buffer } from 'buffer';
import Component from 'vue-class-component';
import Router from 'vue-router';
import accountPages from '@/router/accountPages';
import clientPages from '@/router/clientPages';
import sharedPages from '@/router/sharedPages';
import managerPages from '@/router/managerPages';
import errorPages from '@/router/errorPages';
import { Authority } from '@/shared/security/authority';
import localStorageFacade, { StorageKey } from '@/shared/local-store-facade/LocalStoreFacade';
import HomePage from '@/pages/home/HomePage.vue';
import adminPages from '@/router/adminPages';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate' // for vue-router 2.2+
]);

Vue.use(Router);

const redirectBasedOnRole = (to, from, next) => {
  if (localStorageFacade.get(StorageKey.AUTH_TOKEN) != null) {
    const token = localStorageFacade.get(StorageKey.AUTH_TOKEN);
    const decodedToken = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf-8'));

    const role = decodedToken.auth.split(',').filter(e => {
      return e != Authority.USER;
    })[0];

    switch (role) {
      case Authority.ADMIN:
        next({ path: '/admin/user-management', replace: true });
        break;
      case Authority.CLIENT:
        next({ name: 'exploreClient', replace: true });
        break;
      case Authority.STEMDOER:
        next({ path: '/', replace: true });
        break;
      case Authority.RRHH:
        next({ path: '/rrhhHome', replace: true });
        break;
      case Authority.GESTOR:
        next({ name: 'teamRequestsManager', replace: true });
        break;
      default:
        next();
    }
  } else {
    next();
  }
};

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
      beforeEnter: redirectBasedOnRole
    },
    ...adminPages,
    ...accountPages,
    ...clientPages,
    ...sharedPages,
    ...managerPages,
    ...errorPages
  ]
});

export default router;
