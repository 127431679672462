import { defineComponent, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { useStore } from '@/plugins/vuex';
import { useRouter } from '@/plugins/router';
import { ButtonGroupModals } from '../../../buttonsGroup';

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    }
  },
  components: {
    SButton,
    SModalCentered
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };

    const handleClose = () => {
      store.commit('cartStore/clearCart');
      props.onClose();
      router.push({ name: 'exploreManager' });
    };

    return {
      customModalProps,
      handleClose
    };
  }
});
