import { defineComponent, inject } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { ButtonGroupModals } from '../../../buttonsGroup';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { TeamRequest } from '@/shared/model/team-request.model';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { useI18N } from '@/plugins/i18n';

export default defineComponent({
  props: {
    onSuccess: {
      type: Function,
      required: true
    },
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    refreshTeamRequest: {
      type: Function,
      required: true
    }
  },
  components: {
    SButton,
    SModalCentered
  },
  setup(props) {
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };
    const i18n = useI18N();
    const handleClose = () => {
      props.onClose(ButtonGroupModals.NEW_PROPOSAL);
    };

    const handleAccept = () => {
      if (props.teamRequest.status === RequestStatus.PENDING) {
        teamRequestService.validateAndPartialUpdate(props.teamRequest);
      } else if (props.teamRequest.status === RequestStatus.VALIDATED) {
        teamRequestService.partialUpdate(props.teamRequest);
      }
      props.onSuccess(i18n.t('teamRequestDetail.modal.manager.sendNewProposal.popUp'));
      props.refreshTeamRequest();
      props.onClose(ButtonGroupModals.NEW_PROPOSAL);
    };

    return {
      customModalProps,
      handleClose,
      handleAccept
    };
  }
});
