<template>
  <Explore :teamRequestId="teamRequestId" :loadTrToCart="loadTrToCart" />
</template>

<script lang="ts" setup>
import Explore from '@/sections/shared/explore/Explore.vue';
import { useRouter } from '@/plugins/router';

const router = useRouter();
const teamRequestId = router.currentRoute.query['tr']?.toString();
const loadTrToCart: boolean = !router.currentRoute.query['not-load'] || router.currentRoute.query['not-load'] === 'false';
</script>
