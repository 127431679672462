import { defineComponent, inject } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { TeamRequest } from '@/shared/model/team-request.model';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    onOpenModal: {
      type: Function,
      required: true
    }
  },
  components: {
    SButton
  },
  setup(props) {
    const teamRequestService: TeamRequestService = inject('teamRequestService');

    const sendNewProposal = () => {
      if (props.teamRequest.status === RequestStatus.VALIDATED) {
        teamRequestService
          .partialUpdate(props.teamRequest)
          .then(res => {
            props.onOpenModal({ id: res.id });
          })
          .catch(error => {
            console.error(error);
          });
      } else if (props.teamRequest.status === RequestStatus.PENDING) {
        teamRequestService
          .validateAndPartialUpdate(props.teamRequest)
          .then(res => {
            props.onOpenModal({ id: res.id });
          })
          .catch(error => {
            console.error(error);
          });
      }
      props.onOpenModal();
    };

    return {
      sendNewProposal
    };
  }
});
