/* eslint-disable prettier/prettier */
import { defineComponent, ref, watch, onMounted } from 'vue';
import { useStore } from '@/plugins/vuex';
import FilterTag from './filter-tag/FilterTag.vue';
import Filters, { FilterFields } from '../../model/Filters';

export default defineComponent({
  components: {
    FilterTag
  },
  setup() {
    const store = useStore();
    const tags = ref<Filters>(Filters.empty());

    onMounted(() => {
      tags.value = getTags();
    });

    watch(
      () => store.getters['filterStore/filters'],
      (newVal: Filters) => {
        tags.value = getTags();
      },
      { deep: true }
    );

    const getTags = () => {
      const tags = store.getters['filterStore/filters'];
      return tags.filter(tags => tags.field !== FilterFields.Available && tags.field !== FilterFields.Technology);
    };

    const handleClear = () => {
      const filters = store.state.filterStore.filters;
      const techFilter = filters.find(filter => filter.field === 'tech');

      store.commit('filterStore/clearAllFilters');

      if (techFilter) {
        store.commit('filterStore/addFilter', techFilter);
      }
    };

    return { tags, handleClear, FilterFields };
  }
});
