var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "4" } }, [
        _c(
          "p",
          {
            staticClass: "text-body-1-bold ma-0 pa-0",
            domProps: {
              textContent: _vm._s(
                _vm.$t("explore.stemdoer-detail.availability")
              ),
            },
          },
          [_vm._v("Disponibilidad")]
        ),
      ]),
      _vm._v(" "),
      _setup.props.stemdoer.availability.available ||
      _setup.props.stemdoer.availability.availableAt
        ? _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "4" } }, [
            _c("p", { staticClass: "ma-0 pa-0" }, [
              _c(
                "span",
                {
                  staticClass: "text-subtitle-1 text--text text--base",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("explore.stemdoer-detail.availabilityFrom")
                    ),
                  },
                },
                [_vm._v("Desde:")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "text-body-1-bold text--base text--text" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _setup.props.stemdoer.availability.available
                          ? _vm.$t("explore.stemdoer-detail.Immediate")
                          : _setup.props.stemdoer.availability.availableAt
                          ? _setup.props.stemdoer.availability.availableAt
                          : ""
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _setup.props.stemdoer.availability.available ||
      _setup.props.stemdoer.availability.availableAt
        ? _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "4" } }, [
            _c("p", { staticClass: "ma-0 pa-0" }, [
              _c(
                "span",
                {
                  staticClass: "text-subtitle-1 text--text text--base",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("explore.stemdoer-detail.availabilityTo")
                    ),
                  },
                },
                [_vm._v("Hasta:")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "text-body-1-bold text--base text--text" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _setup.props.stemdoer.availability.available
                          ? _vm.$t("explore.stemdoer-detail.unlimited")
                          : _setup.props.stemdoer.availability.availableAt
                          ? _vm.$t("explore.stemdoer-detail.unlimited")
                          : ""
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_setup.props.stemdoer.availability.available &&
      _setup.props.stemdoer.availability.availableAt === null
        ? _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "8" } }, [
            _c(
              "p",
              {
                staticClass: "ma-0 pa-0 text-body-1-bold",
                staticStyle: { color: "#ef2630" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("explore.stemdoer-detail.notAvailable")
                  ),
                },
              },
              [_vm._v("\n      Este candidato no está disponible\n    ")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }