import { defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import { IRate } from '@/shared/model/rate.model';
import { SeniorityLevel } from '@/shared/model/enumerations/seniority-level.model';
import Filters, { FilterFields } from '@/sections/shared/explore/model/Filters';

export interface IRangeSliderStepsRateState extends Array<number> {
  length: 2;
  0: number;
  1: number;
}

export default defineComponent({
  props: {
    filterField: String,
    action: {
      type: Function
    },
    rates: {
      type: Array as () => IRate[],
      required: true
    },
    tempFilters: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const step = ref(0);
    const min = ref(0);
    const max = ref(0);
    const value = ref<IRangeSliderStepsRateState>([0, 0]);

    onMounted(() => {
      initComponentState(props.rates);
      if (props.filterField) {
        value.value = getComponentState();
      }
    });

    watch(
      () => props.rates,
      (rates: IRate[]) => {
        initComponentState(props.rates);
      },
      { deep: true }
    );

    if (!props.tempFilters) {
      watch(
        () => store.getters['filterStore/filters'],
        (filters: Filters) => {
          value.value = getComponentState();
        },
        { deep: true }
      );
    }

    if (props.tempFilters) {
      watch(
        () => store.getters['filterStore/tempFilters'],
        (filters: Filters) => {
          value.value = getComponentState();
        },
        { deep: true }
      );
    }

    const initComponentState = (rates: IRate[]) => {
      const juniorRate: number = rates.find(rate => rate.seniority === SeniorityLevel.JUNIOR).value;
      const minRate = Math.floor(juniorRate / 10) * 10;
      min.value = minRate;

      const seniorRate: number = rates.find(rate => rate.seniority === SeniorityLevel.SENIOR).value;
      const maxRate = Math.ceil(seniorRate / 10) * 10;
      max.value = maxRate;

      value.value = [minRate, maxRate];
      step.value = (maxRate - minRate) / 10;
    };

    const getComponentState = (): IRangeSliderStepsRateState => {
      let range;
      const payload = props.filterField as FilterFields;
      if (!props.tempFilters) {
        range = store.getters['filterStore/getRangeSliderStepsRateState'](payload);
      } else {
        range = store.getters['filterStore/getRangeSliderStepsRateTempState'](payload);
      }

      if (range[0] === 0 && range[1] === 0) {
        return [min.value, max.value];
      }
      return range;
    };

    const hadleChange = (val: IRangeSliderStepsRateState) => {
      props.action(props.filterField, val);
    };

    return {
      step,
      min,
      max,
      value,
      hadleChange
    };
  }
});
