import Filter from '../filter.model';

abstract class FilterSingle implements Filter {
  id: string;
  field: string;

  constructor(id: string, field: string) {
    this.id = id;
    this.field = field;
  }

  getFieldValueOf(entity: any): any {
    const fieldParts = this.field.split('.');
    let value = entity;
    for (const part of fieldParts) {
      value = value[part];
    }
    return value;
  }

  abstract filter(entity: any): boolean;

  abstract toString(): string;
}
export default FilterSingle;
