import { defineComponent, computed, ref } from 'vue';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import { useI18N } from '@/plugins/i18n';
import { useRouter } from '@/plugins/router';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { LabelStyles } from '@/components/stemdo-components/s-label/sLabel';
import SLabel from '@/components/stemdo-components/s-label/SLabel.vue';
import SPagination from '@/components/stemdo-components/s-pagination/SPagination.vue';

export default defineComponent({
  props: {
    teamRequests: {
      type: Array,
      required: true
    }
  },
  components: {
    SAvatarStemdoer,
    SLabel,
    SPagination
  },
  setup(props) {
    const i18n = useI18N();
    const router = useRouter();
    const ITEMS_PER_PAGE = 10;
    const offset = ref(0);
    const teamRequestsInPage = computed(() => {
      const start = offset.value * ITEMS_PER_PAGE;
      const end = start + ITEMS_PER_PAGE;
      return props.teamRequests.slice(start, end);
    });
    const headers = computed(() => [
      { text: i18n.t('requests-filter-list.list.client'), value: 'client', width: '40%' },
      { text: i18n.t('requests-filter-list.list.status.title'), value: 'status', width: '12%' },
      { text: i18n.t('requests-filter-list.list.team'), value: 'stemdoers', width: '10%' },
      { text: i18n.t('requests-filter-list.list.init'), value: 'initDate', width: '9%' },
      { text: i18n.t('requests-filter-list.list.last-revision'), value: 'logSummary.lastView', width: '15%' },
      { text: i18n.t('requests-filter-list.list.revision'), value: 'logSummary.numberOfViews', width: '6%' }
    ]);

    const getStatusDetails = (status: string) => {
      let translation = '';
      let labelStyle = '';
      switch (status) {
        case RequestStatus.ON_GOING:
          translation = i18n.t('requests-filter-list.list.status.approved') as string;
          labelStyle = LabelStyles.Tertiary;
          break;
        case RequestStatus.PENDING:
          translation = i18n.t('requests-filter-list.list.status.pending') as string;
          labelStyle = LabelStyles.Quinary;
          break;
        case RequestStatus.VALIDATED:
          translation = i18n.t('requests-filter-list.list.status.validated') as string;
          labelStyle = LabelStyles.Quinary;
          break;
        case RequestStatus.CANCELLED_BY_MANAGER:
          translation = i18n.t('requests-filter-list.list.status.rejected') as string;
          labelStyle = LabelStyles.Quaternary;
          break;
        case RequestStatus.CANCELLED_BY_CLIENT:
          translation = i18n.t('requests-filter-list.list.status.rejected') as string;
          labelStyle = LabelStyles.Quaternary;
          break;
        case RequestStatus.SAVED:
          translation = i18n.t('requests-filter-list.list.status.saved') as string;
          labelStyle = LabelStyles.Secondary;
          break;
        default:
          break;
      }
      return { translation, labelStyle };
    };
    const handleClickDetail = teamRequest => {
      router.push({ name: 'teamRequestByIdManager', params: { id: teamRequest.id } });
    };

    const onPageChange = (newPage: number) => {
      offset.value = newPage - 1;
    };

    return {
      teamRequestsInPage,
      ITEMS_PER_PAGE,
      headers,
      getStatusDetails,
      handleClickDetail,
      onPageChange
    };
  }
});
