import { render, staticRenderFns } from "./SExternalLink.vue?vue&type=template&id=fbd61c76&scoped=true"
import script from "./sExternalLink.ts?vue&type=script&lang=ts&external"
export * from "./sExternalLink.ts?vue&type=script&lang=ts&external"
import style0 from "./SExternalLink.vue?vue&type=style&index=0&id=fbd61c76&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbd61c76",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fbd61c76')) {
      api.createRecord('fbd61c76', component.options)
    } else {
      api.reload('fbd61c76', component.options)
    }
    module.hot.accept("./SExternalLink.vue?vue&type=template&id=fbd61c76&scoped=true", function () {
      api.rerender('fbd61c76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/stemdo-components/s-external-link/SExternalLink.vue"
export default component.exports