import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  props: {
    defaultText: {
      type: String
    },
    placeholder: {
      type: String,
      required: true
    },
    onChange: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const searchText = ref<string>();

    watch(
      () => props.defaultText,
      newValue => {
        searchText.value = newValue;
        handleChange(newValue);
      }
    );

    const handleChange = text => {
      props.onChange(text);
    };

    return {
      searchText,
      handleChange
    };
  }
});
