import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import StemdoerCard from './stemdoer-card/StemdoerCard.vue';
import { IRate } from '@/shared/model/rate.model';
import ModalStemdoerDetail from '@/sections/shared/stemdoer-detail/ModalStemdoerDetail.vue';

export default defineComponent({
  components: {
    StemdoerCard,
    ModalStemdoerDetail
  },
  props: {
    stemdoers: {
      type: Array as () => IStemdoer[],
      required: true
    },
    addStemdoerToCart: {
      type: Function,
      required: true
    },
    rates: {
      type: Array as () => IRate[],
      required: true
    }
  },

  setup() {
    const selectedStemdoer = ref<IStemdoer>({});
    const isStemdoerDetailModalOpen = ref(false);

    onMounted(() => {
      window.addEventListener('keydown', handleKeyDown);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleKeyDown);
    });

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeStemdoerDetailModal();
      }
    };

    const closeStemdoerDetailModal = () => {
      document.documentElement.style.overflow = 'auto';
      isStemdoerDetailModalOpen.value = false;
    };

    const openStemdoerDetailModal = (stemdoer: IStemdoer) => {
      selectedStemdoer.value = stemdoer;
      isStemdoerDetailModalOpen.value = true;
    };

    return { selectedStemdoer, isStemdoerDetailModalOpen, openStemdoerDetailModal, closeStemdoerDetailModal };
  }
});
