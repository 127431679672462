export interface ITRLogSummary {
  numberOfViews: number;
  lastView?: string;
}

export class TRLogSummary implements ITRLogSummary {
  numberOfViews: number;
  lastView?: string;

  constructor(data: ITRLogSummary) {
    this.numberOfViews = data?.numberOfViews;
    this.lastView = data?.lastView;
  }

  public get lastViewDate(): Date | null {
    return this.lastView ? new Date(this.lastView) : null;
  }

  static empty = () => {
    return new TRLogSummary({
      numberOfViews: 0,
      lastView: null
    });
  };
}
