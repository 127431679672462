import { defineComponent, inject, PropType, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';

import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { TeamRequest } from '@/shared/model/team-request.model';
import { ButtonGroupModals } from '../../../buttonsGroup';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    refreshTeamRequest: {
      type: Function,
      required: true
    },
    onOpenModal: {
      type: Function,
      required: true
    }
  },
  components: {
    SButton,
    SModalCentered
  },
  setup(props) {
    const validateRequest = () => {
      props.onOpenModal(ButtonGroupModals.TR_VALIDATED);
    };

    const cancelRequest = () => {
      props.onOpenModal(ButtonGroupModals.TR_CANCELLED);
    };

    return {
      validateRequest,
      cancelRequest
    };
  }
});
