import { defineComponent, inject, onMounted, ref } from 'vue';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import StemdoerDetail from '@/sections/shared/stemdoer-detail/StemdoerDetail.vue';
import StemdoerService from '@/sections/admin/entities/stemdoer/stemdoer.service';

export default defineComponent({
  props: {
    stemdoerId: {
      type: String,
      required: true
    }
  },
  components: {
    StemdoerDetail
  },
  setup(props) {
    const stemdoer = ref<IStemdoer | null>(null);
    const stemdoerService: StemdoerService = inject('stemdoerService');
    const isLoaded = ref(false);
    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('h')) {
        stemdoerService
          .find(props.stemdoerId, true)
          .then(res => {
            stemdoer.value = res;
            isLoaded.value = true;
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        stemdoerService
          .find(props.stemdoerId)
          .then(res => {
            stemdoer.value = res;
            isLoaded.value = true;
          })
          .catch(err => {
            console.error(err);
          });
      }
    });

    return {
      stemdoer,
      isLoaded
    };
  }
});
