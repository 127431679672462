var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-pagination", {
    staticClass: "pagination",
    attrs: { length: _vm.totalPages, "total-visible": 6, rounded: "circle" },
    on: { input: _vm.onChange },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v
      },
      expression: "page",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }