var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-container",
              {
                class: [
                  "pl-6 py-0 card-stemdoer",
                  {
                    "card-stemdoer--hover":
                      hover && _vm.cardOptions.isClickable === true,
                  },
                ],
                style: { opacity: _vm.cardOptions.isDisabled ? "0.7" : "1" },
                on: {
                  click: function ($event) {
                    _vm.cardOptions.isClickable === true
                      ? _vm.onClick(_vm.stemdoer.id)
                      : ""
                  },
                },
              },
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "py-4" },
                      [
                        _c(
                          "v-row",
                          { staticClass: "mb-4", attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center mr-4",
                                attrs: { cols: "auto" },
                              },
                              [
                                _c("SAvatarStemdoer", {
                                  staticStyle: {
                                    height: "40px !important",
                                    width: "40px !important",
                                  },
                                  attrs: {
                                    stemdoId: _vm.stemdoer.stemdoId,
                                    size: "40px",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-col", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-body-1 color-text-black",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.stemdoer.name +
                                                " " +
                                                _vm.stemdoer.surname.charAt(0) +
                                                "."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-col", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-body-1-bold color-text-black",
                                        },
                                        [_vm._v(_vm._s(_vm.stemdoer.position))]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          _vm._l(
                            _vm.firstFourTechnologyScores,
                            function (tech, index) {
                              return _c(
                                "v-chip",
                                { key: index, staticClass: "mr-2" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "technologyMaster.list." +
                                            tech.idTechnology
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                        _vm._v(" "),
                        _vm.cardOptions.displayInterestTag === true
                          ? _c(
                              "v-row",
                              {
                                staticClass: "mt-4",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("TagInterest", {
                                      attrs: {
                                        teamRequests: _vm.stemdoer.teamRequests,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "py-2", attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-row",
                          {
                            staticStyle: { height: "34px" },
                            attrs: { justify: "end", "no-gutters": "" },
                          },
                          [
                            _vm.cardOptions.isDeletable
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "18px",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDeleteClick(
                                          _vm.stemdoer.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/content/svgs/close-grey.svg",
                                        alt: "Delete",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.cardOptions.isRateEditable
                          ? _c(
                              "v-row",
                              {
                                staticClass: "mr-2",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { "no-gutters": "" },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "text-subtitle-4 text--text text--lighten-1",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("sCardStemdoer.hourlyRate")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "input-background",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                            },
                                          },
                                          [
                                            _c("SInputNumber", {
                                              attrs: {
                                                value: _vm.rate,
                                                onChange: _vm.onRateChange,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("v-col", { staticClass: "ml-2" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "btn-data input-background",
                                            },
                                            [
                                              _c("v-img", {
                                                staticStyle: {
                                                  transform: "scale(0.8)",
                                                },
                                                attrs: {
                                                  width: 22,
                                                  src: "/content/svgs/data.svg",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cardOptions.isRateVisible
                          ? _c(
                              "v-row",
                              {
                                staticClass: "mt-2 mr-4",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-h5 text--text text--base",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.rate != null &&
                                                      !isNaN(_vm.rate)
                                                      ? _vm.$n(
                                                          _vm.rate,
                                                          "decimal"
                                                        ) + "€"
                                                      : "---"
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "text-subtitle-4 text--text text--lighten-1",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("sCardStemdoer.hourlyRate")
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }