var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "h-100 pa-0 mx-0",
      style: { backgroundColor: "white", minWidth: "100%" },
    },
    [
      _c(
        "v-row",
        { staticClass: "px-6", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "py-4", attrs: { cols: "2" } },
            [_c("SButtonBack", { attrs: { onClick: _vm.onBackButtonClick } })],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center",
              attrs: { cols: "8" },
            },
            [
              _c("span", {
                staticClass: "text-body-1-bold text--text text--base",
                domProps: { textContent: _vm._s(_vm.title) },
              }),
            ]
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "2" } }, [_vm._t("action")], 2),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-row", { attrs: { "no-gutters": "" } }, [
        _c("div", {
          staticStyle: {
            width: "100%",
            height: "100%",
            border: "0.6px var(--v-border-base) solid",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "px-6", attrs: { "no-gutters": "" } },
        [_c("v-col", { style: { minWidth: "100%" } }, [_vm._t("default")], 2)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }