var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("SModalCentered", {
    attrs: {
      title: _vm.$t(
        "teamRequestDetail.modal.manager.validateTeamRequest.title"
      ),
      isOpen: _vm.isOpen,
      closeModal: _vm.handleClose,
      customProps: _vm.customModalProps,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "text-body-1 text--text" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "teamRequestDetail.modal.manager.validateTeamRequest.body"
                    )
                  ) +
                  "\n    "
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  { staticClass: "mr-6" },
                  [
                    _c("SButton", {
                      attrs: {
                        action: _vm.handleClose,
                        btnStyle: "tertiary",
                        sText: _vm.$t(
                          "teamRequestDetail.modal.manager.validateTeamRequest.primaryButtonText"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "mr-6" },
                  [
                    _c("SButton", {
                      attrs: {
                        action: _vm.handleAccept,
                        sText: _vm.$t(
                          "teamRequestDetail.modal.manager.validateTeamRequest.secondaryButtonText"
                        ),
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }