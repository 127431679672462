import { computed, defineComponent, ref } from 'vue';
import { useI18N } from '@/plugins/i18n';

export default defineComponent({
  props: {
    filterField: String,
    value: {
      type: Number,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    isActive: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const i18n = useI18N();
    const isHovered = ref(false);
    const seniorityLevelText = computed(() => {
      if (props.value === 0) {
        return i18n.t('experienceLevel.explorer').toString();
      } else if (props.value === 1) {
        return i18n.t('experienceLevel.strategist').toString();
      } else if (props.value === 2) {
        return i18n.t('experienceLevel.visionary').toString();
      }
      throw new Error('Invalid value in ExperienceLevelFilter');
    });

    const handleClick = () => {
      props.action(props.value);
    };

    return {
      seniorityLevelText,
      isHovered,
      handleClick
    };
  }
});
