import { render, staticRenderFns } from "./NewProposalGroup.vue?vue&type=template&id=7d1f9cf2"
import script from "./newProposalGroup.ts?vue&type=script&lang=ts&external"
export * from "./newProposalGroup.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d1f9cf2')) {
      api.createRecord('7d1f9cf2', component.options)
    } else {
      api.reload('7d1f9cf2', component.options)
    }
    module.hot.accept("./NewProposalGroup.vue?vue&type=template&id=7d1f9cf2", function () {
      api.rerender('7d1f9cf2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/sections/shared/checkout/buttons-group/groups/manager/new-proposal-group/NewProposalGroup.vue"
export default component.exports