import { defineComponent, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { ButtonGroupModals } from '../../../buttonsGroup';
import { useRouter } from '@/plugins/router';

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    teamRequestId: {
      type: String
    }
  },
  components: {
    SButton,
    SModalCentered
  },
  setup(props) {
    const router = useRouter();
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };

    const handleClose = () => {
      props.onClose();
      router.push({ name: 'teamRequestByIdManager', params: { id: props.teamRequestId } });
    };

    return {
      customModalProps,
      handleClose
    };
  }
});
