import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import { useI18N } from '@/plugins/i18n';
import Filters, { FilterFields } from '@/sections/shared/explore/model/Filters';
import vuetify from '@/plugins/vuetify';

export interface IAtocompleteState {
  id: string;
  desc: string;
}

export default defineComponent({
  props: {
    filterField: String,
    items: {
      type: Array as () => IAtocompleteState[],
      required: true
    },
    defaultSelectedItem: {
      type: Object as () => IAtocompleteState,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    action: {
      type: Function,
      required: true
    },
    inputHeight: {
      type: Number,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    labelDisplaySelection: {
      type: Boolean,
      default: false
    },
    tempFilters: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const i18n = useI18N();
    const selectedItem = ref(undefined);
    const labelDisplay = ref('');
    const customStyle = computed(() => {
      const themeColors: any = vuetify.framework.theme.currentTheme;
      return props.tempFilters === true ? { '--custom-color': themeColors.text.lighten2 } : { '--custom-color': themeColors.text.lighten1 };
    });

    onMounted(() => {
      const techFilter = getComponentState();
      if (techFilter) {
        selectedItem.value = techFilter;
      } else {
        if (props.defaultSelectedItem) {
          const payload = { field: props.filterField, newSelectedItem: props.defaultSelectedItem };
          store.commit('filterStore/updateAutoCompleteState', payload);
          selectedItem.value = props.defaultSelectedItem;
        }
      }
      setLabelDisplay();
    });

    watch(
      () => props.defaultSelectedItem,
      (defaultSelectedItem: IAtocompleteState) => {
        const techFilter = getComponentState();
        if (!techFilter) {
          const payload = { field: props.filterField, newSelectedItem: defaultSelectedItem };
          store.commit('filterStore/updateAutoCompleteState', payload);
          selectedItem.value = defaultSelectedItem;
          setLabelDisplay();
        }
      },
      { deep: true }
    );

    if (!props.tempFilters) {
      watch(
        () => store.getters['filterStore/filters'],
        (filters: Filters) => {
          selectedItem.value = getComponentState();
          setLabelDisplay();
        },
        { deep: true }
      );
    }

    if (props.tempFilters) {
      watch(
        () => store.getters['filterStore/tempFilters'],
        (filters: Filters) => {
          selectedItem.value = getComponentState();
          setLabelDisplay();
        },
        { deep: true }
      );
    }

    const getComponentState = () => {
      const payload = props.filterField as FilterFields;
      if (props.filterField !== undefined) {
        if (!props.tempFilters) {
          return store.getters['filterStore/getAutoCompleteState'](payload);
        }
        return store.getters['filterStore/getAutoCompleteTempState'](payload);
      }
    };

    const handleChange = (newSelectedItem: IAtocompleteState) => {
      props.action(props.filterField, newSelectedItem);
    };

    const isItemSelected = item => {
      if (selectedItem.value === undefined || selectedItem.value === null) return false;
      if (selectedItem.value.id === item.id) return true;
      return false;
    };

    const handleBlur = () => {
      setLabelDisplay();
    };

    const setLabelDisplay = () => {
      if (!props.labelDisplaySelection) {
        labelDisplay.value = i18n.t(props.placeholder).toString();
      } else {
        const itemDesc = selectedItem.value?.desc;
        labelDisplay.value = `${itemDesc}`;
      }
    };

    const handleFocus = () => {
      labelDisplay.value = '';
    };

    return { customStyle, selectedItem, labelDisplay, handleChange, isItemSelected, handleFocus, handleBlur };
  }
});
