import { render, staticRenderFns } from "./FilterTagList.vue?vue&type=template&id=b5d1eb60&scoped=true"
import script from "./filterTagList.ts?vue&type=script&lang=ts&external"
export * from "./filterTagList.ts?vue&type=script&lang=ts&external"
import style0 from "./FilterTagList.vue?vue&type=style&index=0&id=b5d1eb60&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5d1eb60",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b5d1eb60')) {
      api.createRecord('b5d1eb60', component.options)
    } else {
      api.reload('b5d1eb60', component.options)
    }
    module.hot.accept("./FilterTagList.vue?vue&type=template&id=b5d1eb60&scoped=true", function () {
      api.rerender('b5d1eb60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/sections/shared/explore/top-filter/filter-tag-list/FilterTagList.vue"
export default component.exports